import React, { useEffect, useState } from 'react'
import InputForm from '../../../components/InputForm'
import ButtonSecondary from '../../../components/ButtonSecondary'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    ERROR_REQUIRE,
    KR_USER_CONFIRM_DEPOSIT,
    KR_USER_OFF_DEPOSIT,
    KR_USER_PLACEHODER_SECURITY_CODE,
    KR_USER_TITLE_SECURITY_CODE
} from '../../../translations/kor'
import { useDispatch } from 'react-redux'
import { confirmPasswordNonToken } from '../../../reduxs/actions/auth.action'
import { setLocalStorage } from '../../../utils/localStorage'
import renderError from '../../../components/RenderError'

export default function PinCode() {
    const [isSuccess, setIsSuccess] = useState(false)

    const [isError, setIsError] = useState(false)

    const { account } = useParams()

    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            id: account,
            app_password: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            app_password: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(confirmPasswordNonToken(values))

            const response = result.payload

            if (response.status === 1) {
                setLocalStorage('token', response.data.access_token)
                setLocalStorage('refreshToken', response.data.refresh_token)
                setLocalStorage('role', response.data.role)

                setIsSuccess(response.data.name_user)
            } else {
                setIsError(response.code)
            }
        }
    })

    return (
        <>
            {
                isSuccess
                    ?
                    <WelcomeUser userName={isSuccess} />
                    :
                    <SecurityCode formik={formik} error={isError} setIsError={setIsError} />
            }
        </>
    )
}

const SecurityCode = ({ formik, error, setIsError }) => {
    const navigate = useNavigate()

    const handleCancel = () => {
        navigate(-1)
    }

    return (
        <form className='w-mobile bg-lightGray py-10 px-5 rounded-24 text-white flex flex-col items-center gap-[30px]' onSubmit={formik.handleSubmit}>
            <h2 className='font-bold'>{KR_USER_TITLE_SECURITY_CODE}</h2>

            <InputForm
                type='password'
                name='app_password'
                onChange={(e) => {
                    setIsError(false)
                    formik.handleChange(e)
                }}
                value={formik.values.app_password}
                placeholder={KR_USER_PLACEHODER_SECURITY_CODE}
                className='!py-1.5 !px-3 !text-text14-20'
                max={4}
                error={((formik.touched.app_password && formik.errors.app_password) || error) && (renderError(error) || formik.errors.app_password)}
                forcus={true}
            />

            <div className='flex gap-2 w-full'>
                <ButtonSecondary title={KR_USER_OFF_DEPOSIT} className='w-full !py-1 !px-3' onClick={handleCancel} />
                <ButtonPrimary title={KR_USER_CONFIRM_DEPOSIT} className='w-full !py-1 !px-3' onClick={formik.handleSubmit} />
            </div>
        </form>
    )
}

const WelcomeUser = ({ userName }) => {
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/user/DIKO')
        }, 3000)
    }, [userName, navigate])

    return (
        <p className='text-text30-40 font-bold animate-fadeOut text-white'>Welcome {userName}</p>
    )
}
