import React, { useRef, useState } from 'react'
import { PopupAdmin } from '../../../components/user/Popup'
import { ERROR_REQUIRE, KR_ADMIN_ACCOUNT_CREATE_TOKEN_FORM } from '../../../translations/kor'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { BsCloudUpload } from 'react-icons/bs'
import LabelForm from '../../../components/LabelForm'
import InputForm from '../../../components/InputForm'
import ButtonSecondary from '../../../components/ButtonSecondary'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { URL_SERVER_IMAGE } from '../../../utils/api'
import { updateToken } from '../../../reduxs/actions/auth.action'

export default function UpdateToken({ handleShow, show, token, QAcode, token_id }) {
    const dispatch = useDispatch()
    const inputRef = useRef()

    const [nameFile, setNameFile] = useState()
    const [isError, setIsError] = useState()

    const handleClickInput = () => {
        inputRef.current.click()
    }

    const handleSetImage = (e) => {
        formik.setFieldValue('QAcode', e.target.files)
        setNameFile(e.target.files[0].name)
    }

    const formik = useFormik({
        initialValues: {
            token_id: token_id,
            address: token,
            QAcode: QAcode
        },
        validationSchema: Yup.object({
            token_id: Yup.string().required(ERROR_REQUIRE),
            address: Yup.string().required(ERROR_REQUIRE),
            QAcode: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const formData = new FormData()

            formData.append('token_id', values.token_id)
            formData.append('address', values.address)
            formData.append('QAcode', values.QAcode[0])

            const result = await dispatch(updateToken(formData))

            const res = result.payload

            if (res.status === 1) {
                handleShow()
            } else {
                setIsError(res.code)
            }
        }
    })

    return (
        <PopupAdmin handleShow={handleShow} show={show} className='flex flex-col items-center gap-5'>
            <h3 className='text-text30-40 font-bold'>{KR_ADMIN_ACCOUNT_CREATE_TOKEN_FORM}</h3>

            <form className='flex flex-col items-center w-full gap-5'>
                <div className='w-[200px] aspect-square'>
                    <img src={`${URL_SERVER_IMAGE}${QAcode}`} alt={`${token}`} className='w-full' />
                </div>

                <input ref={inputRef} type='file' hidden onChange={handleSetImage} />

                <button
                    type='button'
                    className='w-full flex justify-between items-center bg-input py-2 px-4 rounded-12 text-deactivate text-text14-20'
                    onClick={handleClickInput}
                >
                    <p>{nameFile ? nameFile : '지갑 주소'}</p>
                    <BsCloudUpload />
                </button>

                <div className='w-full'>
                    <LabelForm label='핸드폰' />
                    <InputForm
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        value={formik.values.address}
                        name='address'
                        onChange={formik.handleChange}
                        error={(formik.touched.address && formik.errors.address) && formik.errors.address}
                    />
                </div>

                <div className='grid grid-cols-2 gap-2 w-full'>
                    <ButtonSecondary title='취소' onClick={handleShow} />
                    <ButtonPrimary title='확인' onClick={formik.handleSubmit} type='submit' />
                </div>
            </form>
        </PopupAdmin>
    )
}
