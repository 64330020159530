import React, { useState } from 'react'
import { FaCaretDown } from 'react-icons/fa'

export default function OptionForm({ data, className, placeholder, handleSelect, error, isSelected, top = false }) {
    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    return (
        <>
            <div className={`w-full flex justify-between items-center rounded-8 text-text14-20 py-1.5 px-3 bg-[#1C2F4E] text-[#a9a9a9] relative ${className}`}>
                <button type='button' onClick={handleShow} className='w-full text-start'>
                    {placeholder}
                </button>

                <FaCaretDown className={`${isShow && 'rotate-180'} duration-200 cursor-pointer`} onClick={handleShow} />

                {
                    isShow
                    &&
                    <div className={`absolute right-0 ${top ? 'bottom-full mb-1' : 'top-full mt-1'} bg-white min-w-[188px] rounded-12 flex flex-col z-10 text-black`}>
                        {
                            data.map(item =>
                                <button
                                    type='button'
                                    key={item.id}
                                    className={`py-2.5 px-4 text-base first:rounded-t-12 last:rounded-b-12 hover:bg-lightBlue ${(isSelected === item.id) && 'bg-lightBlue text-blue'} duration-200`}
                                    onClick={() => {
                                        handleSelect(item.id)
                                        handleShow()
                                    }}
                                >
                                    {item.name}
                                </button>)
                        }
                    </div>
                }
            </div>

            {
                error
                &&
                <span className='text-text10-12 text-red'>{error}</span>
            }
        </>
    )
}
