import React, { useEffect, useState } from 'react'
import {
    ERROR_REQUIRE,
    KR_USER_CONFIRM_DEPOSIT,
    KR_USER_REQUEST_INPUT_WITHDRAW,
    KR_USER_REQUEST_WITHDRAW,
    KR_USER_TITLE_MYWALLET_DEPOSIT,
    KR_USER_TITLE_WITHDRAW
} from '../../../translations/kor'
import { BsChevronExpand } from 'react-icons/bs'
import ButtonPrimary from '../../../components/ButtonPrimary'
import TextareaForm from '../../../components/TextareaForm'
import Popup from '../../../components/user/Popup'
import { PopupConfirmCodePin } from '../../../components/user/PopupConfirmCodePin'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { createWithDraw, getWithDraw } from '../../../reduxs/actions/withdraw.action'
import Loading from '../../../components/Loading'
import { URL_SERVER_IMAGE } from '../../../utils/api'
import DropdownToken from '../../../components/DropdownToken'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { confirmAppPassword } from '../../../reduxs/actions/auth.action'
import InputChangeForm from '../../../components/InputChangeForm'
import { PopupExchangeWallet } from './DepositPage'
import NotifyComponent from '../../../components/NotifyComponent'

export default function Withdraw() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [isShowConfirmPass, setIsShowConfirmPass] = useState(false)
    const [isConfirmPassword, setIsConfirmPassword] = useState(false)
    const [isSelectedToken, setIsSelectedToken] = useState()

    const [isError, setIsError] = useState()

    const [isNotify, setIsNotify] = useState(false)

    const [isShowExchange, setIsShowExchange] = useState(false)

    const { loadingGetWithdraw, dataWithDraw } = useSelector(state => state.withdraw)

    const { game } = useParams()

    // show confirm password
    const handleShowConfirmPass = () => {
        setIsShowConfirmPass(!isShowConfirmPass)
    }

    // select token
    const handleSelectToken = (token) => {
        setIsSelectedToken(token)

        formikWithdraw.setFieldValue('token_id', token.id)
        formikWithdraw.setFieldValue('receiving_wallet', token.token_address)
    }

    const handleShowExchange = () => {
        setIsShowExchange(!isShowExchange)
    }

    // confirm app password
    const formikConfirmPassword = useFormik({
        initialValues: {
            app_password: ''
        },
        validationSchema: Yup.object({
            app_password: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(confirmAppPassword(values))

            if (result.payload.status === 1) {
                const dataSubmit = {
                    data: { ...formikWithdraw.values, amount: formikWithdraw.values.amount * (isSelectedToken?.value_token / dataWithDraw.gamepoint_rate) },
                    game
                }

                const result = await dispatch(createWithDraw(dataSubmit))

                const res = result.payload

                if (res.status === 1) {
                    handleShowConfirmPass()
                    formikConfirmPassword.resetForm()

                    setIsNotify('출금요청이 확인되었습니다')

                    setTimeout(() => {
                        navigate(`/user/${game}/transaction`)
                    }, 2000);
                }
            } else {
                setIsError(result.payload.code)
            }
        }
    })

    // create withdraw
    const formikWithdraw = useFormik({
        initialValues: {
            token_id: '',
            receiving_wallet: '',
            amount: 1
        },
        validationSchema: Yup.object({
            amount: Yup.string().matches(/^[0-9]+$/, "Commission should contain only digits").required(ERROR_REQUIRE),
            receiving_wallet: Yup.string().required(ERROR_REQUIRE),
        }),
        onSubmit: values => {
            if (!isConfirmPassword) {
                handleShowConfirmPass()
            }
        }
    })

    //// use effect ////
    useEffect(() => {
        dispatch(getWithDraw(game))
    }, [game, dispatch])

    useEffect(() => {
        if (dataWithDraw) {
            const token = dataWithDraw.rate[0]

            setIsSelectedToken(token)

            formikWithdraw.setFieldValue('token_id', token.id)
            formikWithdraw.setFieldValue('receiving_wallet', token.token_address)
        }
    }, [dataWithDraw])

    if (loadingGetWithdraw || !dataWithDraw) {
        return <Loading />
    }

    const { gamepoint, rate, gamepoint_rate } = dataWithDraw

    return (
        <>
            <NotifyComponent notify={isNotify} />

            <h2 className='text-text14-20 font-bold'>{KR_USER_TITLE_WITHDRAW}</h2>

            <form className='rounded-16 bg-white text-black p-5 flex flex-col gap-4 items-center flex-grow'>
                <div className='w-full bg-bg-point bg-no-repeat bg-cover p-5 rounded-16 gap-2.5 flex flex-col justify-between text-white'>
                    <p className='text-text14-20'>{gamepoint.gamepoint_name}</p>

                    <p className='text-text20-24 font-bold'>100 {gamepoint.gamepoint_name} = {gamepoint.rate} USDT</p>
                </div>

                <div className='w-full flex flex-col items-center gap-2'>
                    <div className='w-full'>
                        <div className='flex justify-between items-center w-full p-3 bg-lightBlue rounded-12'>
                            <div className='flex items-center gap-2'>
                                <img src={`${URL_SERVER_IMAGE}${gamepoint.logo_gamepoint}`} alt='icon' className='w-[26px] aspect-square rounded-full object-contain' />

                                <span className='text-text14-20'>{gamepoint.gamepoint_name}</span>
                            </div>

                            <input
                                name='amount'
                                className='border-b border-black w-10 text-text14-20 bg-transparent text-center'
                                value={formikWithdraw.values.amount}
                                onChange={formikWithdraw.handleChange}
                                type='number'
                            />
                        </div>
                        {
                            (formikWithdraw.touched.amount && formikWithdraw.errors.amount)
                            &&
                            <p className='text-text10-12 mt-1 text-red'>{formikWithdraw.errors.amount}</p>
                        }
                    </div>

                    <BsChevronExpand />

                    <DropdownToken
                        data={rate}
                        value={formikWithdraw.values.amount}
                        gamepoint_rate={gamepoint_rate}
                        handleSelectToken={handleSelectToken}
                        isSelected={isSelectedToken}
                    />

                    <button className='text-text14-20 text-blue text-end w-full' type='button' onClick={handleShowExchange}>오늘의 환율</button>
                </div>

                <div className='w-full flex flex-col gap-2'>
                    <h3 className='text-text14-20 font-bold'>{KR_USER_TITLE_MYWALLET_DEPOSIT}</h3>

                    <InputChangeForm
                        name='receiving_wallet'
                        onChange={formikWithdraw.handleChange}
                        value={formikWithdraw.values.receiving_wallet}
                        error={(formikWithdraw.touched.receiving_wallet && formikWithdraw.errors.receiving_wallet) && formikWithdraw.errors.receiving_wallet}
                    />
                </div>

                <div className='w-full flex flex-col gap-2'>
                    <h3 className='text-text14-20 font-bold'>{KR_USER_REQUEST_WITHDRAW}</h3>

                    <TextareaForm placeholder={KR_USER_REQUEST_INPUT_WITHDRAW} row={5} />
                </div>

                <ButtonPrimary type='submit' title={KR_USER_CONFIRM_DEPOSIT} className='w-full text-white' onClick={formikWithdraw.handleSubmit} />

            </form>

            <Popup show={isShowConfirmPass} handleShow={handleShowConfirmPass}>
                <PopupConfirmCodePin formik={formikConfirmPassword} isError={isError} setIsError={setIsError} />
            </Popup>

            <PopupExchangeWallet
                handleShowExchange={handleShowExchange}
                isShowExchange={isShowExchange}
            />
        </>
    )
}
