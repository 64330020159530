import React from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { KR_USER_WARNING_DASHBOARD } from '../../translations/kor'

export default function WarningDashboard({ data }) {
    return (
        <section className='p-3 bg-darkblue rounded-8 flex flex-col gap-2'>
            <h3 className='text-text14-20 flex items-center gap-2 font-bold'><IoAlertCircleOutline className='text-text20-24' /> {KR_USER_WARNING_DASHBOARD}</h3>

            <div className='text-text14-20 min-h-[80px] whitespace-pre-line'>{data}</div>
        </section>
    )
}
