import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/api";

export const getLevels = createAsyncThunk(
    'level/get',
    async () => {
        const response = await get(`admin/listRole`)

        return response
    }
)