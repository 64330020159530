import React, { useEffect } from 'react'
import avatar from '../../assets/images/banner.avif'
import { IoIosSearch } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getInforAccount } from '../../reduxs/actions/auth.action'

export default function HeaderAdmin() {
    const dispatch = useDispatch()

    const { nameUser, email } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(getInforAccount())
    }, [])

    return (
        <header className='p-5 bg-bg flex justify-between items-center'>
            <form className='max-w-[790px] w-full relative'>
                <IoIosSearch className='absolute text-deactivate text-xl -translate-y-1/2 top-1/2 left-4' />
                <input type='text' className='w-full py-2 px-4 rounded-12' />
            </form>

            <div className='flex gap-2 items-center'>
                <img src={avatar} alt='' className='w-[42px] aspect-square rounded-full object-cover block' />

                <div>
                    <p className='text-text16-20 font-bold'>{nameUser}</p>
                    <span className='text-text14-20'>{email}</span>
                </div>
            </div>
        </header>
    )
}
