import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    ERROR_REQUIRE,
    KR_USER_CONFIRM_DEPOSIT,
    KR_USER_INPUT_TELEGRAM_MYWALET,
    KR_USER_LABEL_TELEGRAM_MYWALET,
    KR_USER_OFF_DEPOSIT,
    KR_USER_TITLE_TELEGRAM_MYWALET
} from '../../translations/kor'
import * as Yup from 'yup'
import { changeTelegram, confirmAppPassword } from '../../reduxs/actions/auth.action'
import Popup from '../../components/user/Popup'
import { PopupConfirmCodePin } from '../../components/user/PopupConfirmCodePin'
import LabelForm from '../../components/LabelForm'
import InputForm from '../../components/InputForm'
import ButtonSecondary from '../../components/ButtonSecondary'
import ButtonPrimary from '../../components/ButtonPrimary'

export default function ConfirmTelegram({ show, handleShow }) {
    const dispatch = useDispatch()

    const [isConfirm, setIsConfirm] = useState(false)
    const [isError, setIsError] = useState(false)

    const formik = useFormik({
        initialValues: {
            app_password: ''
        },
        validationSchema: Yup.object({
            app_password: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(confirmAppPassword(values))

            if (result.payload.status === 1) {
                setIsConfirm(true)
            }
        }
    })

    return (
        <Popup show={show} handleShow={handleShow}>
            {
                isConfirm
                    ?
                    <PopupChangeTelegram handleShow={handleShow} />
                    :
                    <PopupConfirmCodePin formik={formik} isError={isError} setIsError={setIsError} />
            }
        </Popup>
    )
}

export const PopupChangeTelegram = ({ handleShow }) => {
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            telegram_id: ''
        },
        validationSchema: Yup.object({
            telegram_id: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(changeTelegram(values))

            if (result.payload.status === 1) {
                handleShow()
            }
        }
    })

    return (
        <div className='flex flex-col gap-4'>
            <h4 className='text-text16-20 font-bold text-center'>{KR_USER_TITLE_TELEGRAM_MYWALET}</h4>

            <form className='flex flex-col gap-4 w-full' onSubmit={formik.handleSubmit}>
                <div>
                    <LabelForm label={KR_USER_LABEL_TELEGRAM_MYWALET} htmlFor='telegram_id' />
                    <InputForm
                        name='telegram_id'
                        onChange={formik.handleChange}
                        value={formik.values.telegram_id}
                        placeholder={KR_USER_INPUT_TELEGRAM_MYWALET}
                        error={(formik.touched.telegram_id && formik.errors.telegram_id) && formik.errors.telegram_id}
                    />
                </div>
            </form>

            <div className='grid grid-cols-2 gap-2 text-white'>
                <ButtonSecondary title={KR_USER_OFF_DEPOSIT} onClick={handleShow} />
                <ButtonPrimary type='submit' title={KR_USER_CONFIRM_DEPOSIT} onClick={formik.handleSubmit} />
            </div>
        </div>
    )
}
