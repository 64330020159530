import React from 'react'
import { PopupAdmin } from './user/Popup'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRE, KR_ADMIN_ACCOUNT_EXCHANGE_FORM } from '../translations/kor'
import { URL_SERVER_IMAGE } from '../utils/api'
import ButtonSecondary from './ButtonSecondary'
import ButtonPrimary from './ButtonPrimary'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { changeExchangRate } from '../reduxs/actions/token.action'

export default function ChangeRatePopup({ handleShow, show, data }) {
    const dispatch = useDispatch()

    const { rate, gamepoint } = data

    const { game } = useParams()

    const formik = useFormik({
        initialValues: {
            gamepoint_rate: '',
            BTC: '',
            ETH: '',
            USDT: '',
            TRON: ''
        },
        validationSchema: Yup.object({
            BTC: Yup.string().matches(/^[0-9]+$/, "Commission should contain only digits").required(ERROR_REQUIRE),
            ETH: Yup.string().matches(/^[0-9]+$/, "Commission should contain only digits").required(ERROR_REQUIRE),
            USDT: Yup.string().matches(/^[0-9]+$/, "Commission should contain only digits").required(ERROR_REQUIRE),
            TRON: Yup.string().matches(/^[0-9]+$/, "Commission should contain only digits").required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const dataSubmit = {
                namesite: game,
                "rate": [
                    {
                        "token_id": 1,
                        "value_token": values.BTC,
                        "gamepoint_rate": values.gamepoint_rate
                    },
                    {
                        "token_id": 2,
                        "value_token": values.ETH,
                        "gamepoint_rate": values.gamepoint_rate
                    },
                    {
                        "token_id": 3,
                        "value_token": values.USDT,
                        "gamepoint_rate": values.gamepoint_rate
                    },
                    {
                        "token_id": 4,
                        "value_token": values.TRON,
                        "gamepoint_rate": values.gamepoint_rate
                    }

                ]
            }

            const result = await dispatch(changeExchangRate(dataSubmit))

            const res = result.payload

            if (res.status === 1) {
                handleShow()
            }
        }
    })

    return (
        <PopupAdmin handleShow={handleShow} show={show} className='flex flex-col items-center gap-5'>
            <h3 className='text-text30-40 font-bold'>{KR_ADMIN_ACCOUNT_EXCHANGE_FORM}</h3>

            <form className='flex flex-col items-center gap-5 w-full'>
                <div className='flex flex-col gap-2 w-full'>
                    <div className='flex justify-between items-center w-full'>
                        <div className='flex items-center gap-2'>
                            <img src={`${URL_SERVER_IMAGE}${gamepoint.gamepoint_logo}`} alt='' className='w-[26px] aspect-square rounded-full object-cover' />

                            <span>{gamepoint.gamepoint_name}</span>
                        </div>

                        <input
                            type='number'
                            name='gamepoint_rate'
                            value={formik.values.gamepoint_rate}
                            onChange={formik.handleChange}
                            className='py-1 px-4 rounded-8 w-[120px] bg-input text-center'
                        />
                    </div>

                    {
                        rate.map(item => <ChangeRateItem
                            key={item.id}
                            nameToken={item.name}
                            logo={item.logoToken}
                            item={item}
                            name={item.name}
                            value={formik.values[item.name]}
                            formik={formik}
                            error={(formik.touched[item.name] && formik.errors[item.name]) && formik.errors[item.name]}
                        />)
                    }
                </div>

                <div className='grid grid-cols-2 gap-2 w-full'>
                    <ButtonSecondary title='취소' onClick={handleShow} />
                    <ButtonPrimary title='확인' onClick={formik.handleSubmit} type='submit' />
                </div>
            </form>
        </PopupAdmin>
    )
}

const ChangeRateItem = ({ nameToken, logo, error, formik, name, value }) => {
    return (
        <>

            <div className='flex justify-between items-center w-full'>
                <div className='flex items-center gap-2'>
                    <img src={`${URL_SERVER_IMAGE}${logo}`} alt='' className='w-[26px] aspect-square rounded-full object-cover' />

                    <span>{nameToken}</span>
                </div>

                <input
                    type='number'
                    name={name}
                    onChange={formik.handleChange}
                    value={value}
                    className='py-1 px-4 rounded-8 w-[120px] bg-input text-center'
                />

            </div>

            {
                error
                &&
                <p className='text-text10-12 text-red'>{error}</p>
            }
        </>
    )
}
