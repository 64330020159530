import { configureStore } from "@reduxjs/toolkit";
import authReducer from './reducers/auth.reducer'
import postReducer from './reducers/post.reducer'
import dashboardReducer from './reducers/dashboard.reducer'
import depositReducer from './reducers/deposit.reducer'
import tokenReducer from './reducers/token.reducer'
import withdrawReducer from './reducers/withdraw.reducer'
import walletReducer from './reducers/wallet.reducer'
import transactionReducer from './reducers/transaction.reducer'
import userReducer from './reducers/user.areducer'
import bannerReducer from './reducers/banner.reducer'
import blogReducer from './reducers/blog.reducer'
import gamesiteReducer from './reducers/gamesite.reducer'
import levelReducer from './reducers/level.reducer'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        post: postReducer,
        dashboard: dashboardReducer,
        deposit: depositReducer,
        token: tokenReducer,
        withdraw: withdrawReducer,
        wallet: walletReducer,
        transaction: transactionReducer,
        user: userReducer,
        banner: bannerReducer,
        blog: blogReducer,
        gamesite: gamesiteReducer,
        level: levelReducer,
    }
})
