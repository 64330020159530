import React, { useEffect, useLayoutEffect } from 'react'
import HeaderUser from '../../../components/user/HeaderUser'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import DashboardUserPage from './DashboardUserPage'
import ProtectRouterUser from '../../../components/ProtectRouterUser'
import { checkAuth } from '../../../reduxs/reducers/auth.reducer'
import { useDispatch, useSelector } from 'react-redux'
import { checkGamesiteUser } from '../../../reduxs/actions/auth.action'

export default function UserPage() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { pathname } = useLocation()
    const { game } = useParams()

    const { gamesiteCheck } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(checkAuth())
    }, [dispatch])

    useEffect(() => {
        dispatch(checkGamesiteUser(game))
    }, [game])

    useEffect(() => {
        if ((game !== gamesiteCheck) && gamesiteCheck) {
            navigate(`/user/${gamesiteCheck}`)
        }
    }, [gamesiteCheck, game])

    return (
        <div className='flex flex-col min-h-screen max-w-[467px] w-full overflow-y-scroll overflow-x-hidden hideScroll'>
            <HeaderUser />

            <main className='px-5 text-white flex flex-col gap-4 mb-4 flex-grow w-full'>
                {
                    pathname === `/user/${game}`
                        ?
                        <ProtectRouterUser>
                            <DashboardUserPage />
                        </ProtectRouterUser>
                        :
                        <Outlet />
                }
            </main>
        </div>
    )
}
