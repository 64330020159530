import React, { useEffect, useState } from 'react'
import { KR_ADMIN_MYWALLET_ACCOUNT } from '../../translations/kor'
import CompanyPointItem from '../../components/admin/CompanyPointItem'
import { BsPlus } from 'react-icons/bs'
import InforAccount from '../../sections/admin/MyAccount/InforAccount'
import ChangeRate from '../../sections/admin/MyAccount/ChangeRate'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import { getAccountAdmin } from '../../reduxs/actions/auth.action'
import { getLocalStorage } from '../../utils/localStorage'
import CreateWarning from '../../sections/admin/MyAccount/CreateWarning'
import { getExchangeRate } from '../../reduxs/actions/token.action'
import { useParams } from 'react-router-dom'

export default function MyAccount() {
    const dispatch = useDispatch()
    const { userRole, dataAccountAdmin, loadingGetAccountAdmin } = useSelector(state => state.auth)
    const { dataToken } = useSelector(state => state.token)

    const role = getLocalStorage('role')

    const { game } = useParams()

    const [isShowCreateToken, setIsShowCreateToken] = useState(false)

    const handleShowCreateToken = () => {
        setIsShowCreateToken(!isShowCreateToken)
    }

    useEffect(() => {
        dispatch(getAccountAdmin())
        if (role === 'game-admin') {
            dispatch(getExchangeRate(game))
        }
    }, [game, role])

    if (loadingGetAccountAdmin || !dataAccountAdmin) {
        return <Loading />
    }

    const { token, user, warning } = dataAccountAdmin

    return (
        <>
            <section className='flex flex-col gap-4'>
                <h3 className='text-text16-20 font-bold'>{KR_ADMIN_MYWALLET_ACCOUNT}</h3>

                <div className='flex gap-4'>
                    {
                        token.map(item =>
                            <CompanyPointItem
                                key={item.id}
                                token_id={item.id}
                                copy={true}
                                name={item.name}
                                logo={item.logoToken}
                                token={item.token_address}
                                QAcode={item.QAcode}
                            />)
                    }

                    <button type='button' className='bg-bg-companyPoint p-3 rounded-20 bg-no-repeat bg-cover flex justify-center items-center' onClick={handleShowCreateToken}>
                        <BsPlus className='text-[30px] text-black' />
                    </button>
                </div>
            </section>

            <InforAccount data={user} />

            {
                (userRole || role) === 'game-admin'
                &&
                <div className='grid grid-cols-2 gap-5'>
                    <ChangeRate data={dataToken} />
                    <CreateWarning data={warning} />
                </div>
            }

            {/* <CreateToken handleShow={handleShowCreateToken} show={isShowCreateToken} /> */}
        </>
    )
}
