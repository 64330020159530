import React from 'react'
import { MdOutlineAccessTime } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { KR_USER_VIEWMORE_POSTS } from '../../translations/kor'
import { FiArrowRight } from 'react-icons/fi'

export default function PostItem({ title, time, link }) {
    return (
        <div className='bg-white p-3 text-black rounded-16 flex flex-col gap-2'>
            <Link to={`${link}`} className='text-text14-20 font-bold hover:text-blue duration-300'>{title}</Link>

            <p className='text-text10-12 flex gap-[5px] items-center'>
                <MdOutlineAccessTime />
                {time}
            </p>

            <Link to={`${link}`} className='flex gap-2 items-center text-text10-12 hover:text-blue duration-300 group'>
                {KR_USER_VIEWMORE_POSTS}
                <FiArrowRight className='group-hover:translate-x-0.5 duration-200' />
            </Link>
        </div>
    )
}
