import React from 'react'
import { KR_USER_CONFIRM_DEPOSIT, KR_USER_PLACEHODER_SECURITY_CODE } from '../../translations/kor'
import ButtonPrimary from '../ButtonPrimary'
import InputForm from '../InputForm'
import renderError from '../RenderError'

export const PopupConfirmCodePin = ({ formik, isError, setIsError }) => {
    return (
        <form className='flex flex-col items-center gap-4' onSubmit={formik.handleSubmit}>
            <InputForm
                type='password'
                name='app_password'
                placeholder={KR_USER_PLACEHODER_SECURITY_CODE}
                className='!py-1.5 !px-3 !text-text14-20'
                max={4}
                onChange={(e) => {
                    formik.handleChange(e)
                    setIsError(false)
                }}
                value={formik.values.app_password}
                error={((formik.touched.app_password && formik.errors.app_password) || isError) && (renderError(isError) || formik.errors.app_password)}
            />

            <ButtonPrimary type='submit' title={KR_USER_CONFIRM_DEPOSIT} className='w-[145px] text-white' onClick={formik.handleSubmit} />
        </form>
    )
}
