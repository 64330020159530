import { createSlice } from "@reduxjs/toolkit";
import { createBanners, deleteBanners, getBanners, updateBanners } from "../actions/banner.action";

const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        loadingGetBanner: false,
        dataBanner: undefined,

        loadingCreateBanner: false,

        loadingDeleteBanner: false,

        loadingUpdateBanner: false,
    },
    extraReducers: builder => {
        // get banners
        builder.addCase(getBanners.pending, (state, action) => {
            state.loadingGetBanner = true
        })
        builder.addCase(getBanners.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataBanner = res.data
            }

            state.loadingGetBanner = false
        })

        // create banners
        builder.addCase(createBanners.pending, (state, action) => {
            state.loadingCreateBanner = true
        })
        builder.addCase(createBanners.fulfilled, (state, action) => {
            state.loadingCreateBanner = false
        })

        // delete banners
        builder.addCase(deleteBanners.pending, (state, action) => {
            state.loadingDeleteBanner = true
        })
        builder.addCase(deleteBanners.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                const updateDataBanner = state.dataBanner.filter(item => item.id !== res.data.banner_id)

                state.dataBanner = updateDataBanner
            }

            state.loadingDeleteBanner = false
        })

        // update banners
        builder.addCase(updateBanners.pending, (state, action) => {
            state.loadingUpdateBanner = true
        })
        builder.addCase(updateBanners.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                const updateDataBanner = state.dataBanner.map(item => {
                    if (item.id === res.data.id) {
                        return res.data
                    }

                    return item
                })

                state.dataBanner = updateDataBanner
            }

            state.loadingUpdateBanner = false
        })
    }
})

export const { } = bannerSlice.actions
export default bannerSlice.reducer