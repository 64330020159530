import React, { useState } from 'react'
import { IoMenu } from 'react-icons/io5'
import { Link, useParams } from 'react-router-dom'
import SidebarUser from './SidebarUser'

export default function HeaderUser() {
    const [isSidebar, setIsSidebar] = useState(false)

    const { game } = useParams()

    const handleShowSidebar = () => {
        setIsSidebar(!isSidebar)
    }

    return (
        <>
            <header className='p-5 text-white flex justify-between items-center sticky top-0 bg-bg z-10 w-full'>
                <h1 className='z-0'>
                    <Link to={`/user/${game}`} className='text-text16-20 font-bold'>GameWallet {game}</Link>
                </h1>

                <button onClick={handleShowSidebar}><IoMenu className='text-base' /></button>

                <SidebarUser isSidebar={isSidebar} handleShow={handleShowSidebar} />
            </header>

        </>
    )
}
