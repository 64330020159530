import { createSlice } from "@reduxjs/toolkit";
import { createWithDraw, getWithDraw } from "../actions/withdraw.action";

const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState: {
        loadingGetWithdraw: false,

        dataWithDraw: null,

        loadingCreateWithdraw: false
    },
    extraReducers: builder => {
        // get withdraw
        builder.addCase(getWithDraw.pending, (state, action) => {
            state.loadingGetWithdraw = true
        })
        builder.addCase(getWithDraw.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataWithDraw = res.data
            }

            state.loadingGetWithdraw = false
        })

        // create withdraw
        builder.addCase(createWithDraw.pending, (state, action) => {
            state.loadingCreateWithdraw = true
        })
        builder.addCase(createWithDraw.fulfilled, (state, action) => {
            state.loadingCreateWithdraw = false
        })
    }
})

export const { } = withdrawSlice.actions
export default withdrawSlice.reducer
