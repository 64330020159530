import React, { useRef } from 'react'
import { BsFillCloudArrowUpFill } from 'react-icons/bs'

export default function ImageForm({ name, value, onChange, error, namefile }) {
    const inputRef = useRef()

    const handleSelectInput = () => {
        inputRef.current.click()
    }

    return (
        <>

            <div className='h-[186.67px] bg-input rounded-20 flex flex-col justify-center items-center text-deactivate shadow-shadow-input cursor-pointer' onClick={handleSelectInput}>
                <BsFillCloudArrowUpFill className='text-3xl' />
                <p className='text-center line-clamp-1'>{namefile || '배너 업데이트'}</p>
                <p className='text-xs'>최대 용량 10MB</p>

                <input type='file' hidden name={name} value={value} onChange={(e) => onChange(e)} ref={inputRef} />
            </div>

            {
                error
                &&
                <span className='text-text10-12 text-red'>{error}</span>
            }
        </>
    )
}
