import React from 'react'

export default function statusComponent(status) {
    switch (status) {
        case 1:
            return <div className='py-1.5 px-2.5 text-text14-20 border border-[#B7B7B7] rounded-8 bg-[#fff] text-black w-fit'>요청중</div>;
        case 2:
            return <div className='py-1.5 px-2.5 text-text14-20 border border-[#27B73E] text-[#27B73E] rounded-8 bg-[#C4F3CB] w-fit'>진행중</div>;
        case 3:
            return <div className='py-1.5 px-2.5 text-text14-20 border border-blue text-blue rounded-8 bg-[#BDD6FC] w-fit'>완료</div>;
        case 4:
            return <div className='py-1.5 px-2.5 text-text14-20 border border-[#FF3939] text-[#FF3939] rounded-8 bg-[#FEC6C3] w-fit'>거절</div>;
        case 5:
            return <div className='py-1.5 px-2.5 text-text14-20 border border-[#787878] text-[#787878] rounded-8 bg-[#D7D7D7] w-fit'>보류중</div>;
    }
}
