import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/api";

// get list site
export const getAllGameSite = createAsyncThunk(
    'gamesite/getAll',
    async () => {
        const response = await get(`admin/listSite`)

        return response
    }
)

// check game site
export const checkGameSite = createAsyncThunk(
    'gamesite/check',
    async (namesite) => {
        const response = await get(`auth/find_gamesite/${namesite}`)

        return response
    }
)
