import React from 'react'
import { Link } from 'react-router-dom'
import { URL_SERVER_IMAGE } from '../../../utils/api'

export default function WalletItem({ logo, name, total, link = '#' }) {
    return (
        <Link to={`${link}`} className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-2'>
                <img src={`${URL_SERVER_IMAGE}${logo}`} alt='icon' className='w-[26px] aspect-square rounded-full object-contain' />

                <span className='text-text14-20'>{name}</span>
            </div>

            <p className='text-text14-20'>{total}</p>
        </Link>
    )
}
