import React, { useEffect } from 'react'
import { KR_USER_TITLE_POSTS } from '../../../translations/kor'
import PostItem from '../../../components/user/PostItem'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPost } from '../../../reduxs/actions/post.action'

export default function PostPage() {
    const dispatch = useDispatch()

    const { dataAllPost, loadingGetAllPost } = useSelector(state => state.post)

    useEffect(() => {
        dispatch(getAllPost())
    }, [dispatch])

    if (loadingGetAllPost || !dataAllPost) {
        return <p className='text-text14-20'>Loading..</p>
    }

    return (
        <>
            <h2 className='text-text14-20 font-bold'>{KR_USER_TITLE_POSTS}</h2>

            <section className='flex flex-col gap-2'>
                {
                    dataAllPost.map(item => <PostItem key={item.id} time={item.created_at} title={item.title} link={item.id} />)
                }
            </section>
        </>
    )
}
