import React from 'react'

export default function TextareaForm({ placeholder, row, className, error, name, value, onChange }) {
    return (
        <>
            <textarea
                name={name}
                className={`bg-lightBlue rounded-12 py-1.5 px-3 text-text14-20 ${className}`}
                value={value}
                rows={row}
                placeholder={placeholder}
                onChange={onChange}
            />

            {
                error
                &&
                <span className='text-text10-12 text-red'>{error}</span>
            }
        </>
    )
}
