import React from 'react'
import { URL_SERVER_IMAGE } from '../../../utils/api'
import { ChangeRateItem } from '../MyAccount/ChangeRate'

export default function ListChangeRate({ data }) {
    const { gamepoint, rate } = data

    return (
        <div className='bg-darkblue p-5 rounded-20 flex flex-col gap-5 gap-x-5 gap-y-4'>
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                    <img src={`${URL_SERVER_IMAGE}${gamepoint.gamepoint_logo}`} alt='' className='w-[26px] aspect-square rounded-full object-cover' />

                    <span>{gamepoint.gamepoint_name}</span>
                </div>

                <p className='py-1 px-4 rounded-8 w-[120px] bg-input text-center'>{gamepoint.gamepoint_rate}</p>
            </div>

            {
                rate?.map(item =>
                    <ChangeRateItem
                        key={item.id}
                        logo={item.logoToken}
                        name={item.name}
                        id={item.id}
                        value={item.value_token}
                        valueToken={item.value_token}
                    />)
            }
        </div>
    )
}
