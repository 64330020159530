import React, { useState } from 'react'
import LabelForm from '../../../components/LabelForm'
import InputForm from '../../../components/InputForm'
import { ERROR_REQUIRE, KR_USER_CONFIRM_DEPOSIT, KR_USER_EMAIL_CONFIRM, KR_USER_EMAIL_PLACEHODER_CONFIRM, KR_USER_OFF_DEPOSIT, KR_USER_PLACEHODER_SECURITY_CODE, KR_USER_TELEGRAM_CONFIRM, KR_USER_TELEGRAM_PLACEHODER_CONFIRM, KR_USER_TITLE_SECURITY_CODE } from '../../../translations/kor'
import ButtonSecondary from '../../../components/ButtonSecondary'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { updateInforLogin } from '../../../reduxs/actions/auth.action'
import renderError from '../../../components/RenderError'

export default function ConfirmAccount() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { account, gamesite } = useParams()

    const [isError, setIsError] = useState()

    const formik = useFormik({
        initialValues: {
            telegram_id: '',
            email: '',
            security_code: '',
            id: account
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            telegram_id: Yup.string(),
            email: Yup.string(),
            security_code: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const dataSubmit = {
                data: values,
                game: gamesite
            }

            const response = await dispatch(updateInforLogin(dataSubmit))

            if (response.payload.status === 1) {
                navigate(`/auth/${gamesite}/pin-code/${account}`)
            } else {
                setIsError(response.payload.code)
            }
        }
    })

    const handleCancel = () => {
        navigate(`/auth/${gamesite}`)
    }

    return (
        <form className='w-mobile bg-lightGray py-10 px-5 rounded-24 text-white flex flex-col items-center gap-[30px]' onSubmit={formik.handleSubmit}>
            <h2 className='font-bold'>GameWallet {gamesite}</h2>

            <div className='w-full flex flex-col gap-4'>
                <div>
                    <LabelForm label={KR_USER_TELEGRAM_CONFIRM} htmlFor='telegram_id' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='telegram_id'
                        onChange={(e) => {
                            setIsError(false)
                            formik.handleChange(e)
                        }}
                        value={formik.values.telegram_id}
                        placeholder={KR_USER_TELEGRAM_PLACEHODER_CONFIRM}
                        className='!py-1.5 !px-3 !text-text14-20'
                        error={(formik.touched.telegram_id && formik.errors.telegram_id) && formik.errors.telegram_id}
                        forcus={true}
                    />
                </div>

                <div>
                    <LabelForm label={KR_USER_EMAIL_CONFIRM} htmlFor='email' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='email'
                        onChange={(e) => {
                            setIsError(false)
                            formik.handleChange(e)
                        }}
                        value={formik.values.email}
                        placeholder={KR_USER_EMAIL_PLACEHODER_CONFIRM}
                        className='!py-1.5 !px-3 !text-text14-20'
                        error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                    />
                </div>

                <div>
                    <LabelForm label={KR_USER_TITLE_SECURITY_CODE} htmlFor='security_code' className='!text-text14-20' />
                    <InputForm
                        type='password'
                        name='security_code'
                        onChange={(e) => {
                            setIsError(false)
                            formik.handleChange(e)
                        }}
                        value={formik.values.security_code}
                        placeholder={KR_USER_PLACEHODER_SECURITY_CODE}
                        className='!py-1.5 !px-3 !text-text14-20'
                        max={4}
                        error={(formik.touched.security_code && formik.errors.security_code) && formik.errors.security_code}
                    />

                    <span className='text-text10-12 text-red'>* 보안코드는 입금, 출금 확인을 위해 반드시 입력하여야 합니다.</span>
                </div>
            </div>

            {
                isError
                &&
                <p className='text-text14-20 text-red'>{renderError(isError)}</p>
            }

            <div className='flex gap-2 w-full'>
                <ButtonSecondary title={KR_USER_OFF_DEPOSIT} className='w-full !py-1 !px-3' onClick={handleCancel} />
                <ButtonPrimary title={KR_USER_CONFIRM_DEPOSIT} className='w-full !py-1 !px-3' onClick={formik.handleSubmit} />
            </div>
        </form>
    )
}
