import React, { useEffect } from 'react'
import { clearLocalStorage, getLocalStorage } from '../utils/localStorage'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

export default function ProtectRouterAdmin({ children }) {
    const navigate = useNavigate()

    const token = getLocalStorage('token')
    const role = getLocalStorage('role')

    const { isAuthentication, userRole } = useSelector(state => state.auth)

    useEffect(() => {
        if (!(role === 'total-admin' || role === 'agent-admin' || role === 'game-admin')) {
            clearLocalStorage();
            navigate(`/admin/auth`);
        }
    }, [role, navigate]);

    return (isAuthentication || token) ? children : <Navigate to={`/admin/auth`} />
}
