import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/api";

export const getAllWallet = createAsyncThunk(
    'wallet/getall',
    async (game) => {
        const response = await get(`admin/wallet?namesite=${game}`)

        return response
    }
)
