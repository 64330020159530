import { createSlice } from "@reduxjs/toolkit";
import { createBlog, deleteBlog, getBlog, updateBlog } from "../actions/blog.action";

const blogSlice = createSlice({
    name: 'blog',
    initialState: {
        loadingGetBlog: false,
        dataBlog: undefined,

        loadingCreateBlog: false,

        loadingDeleteBlog: false,

        loadingUpdateBlog: false,
    },
    extraReducers: builder => {
        // get blog
        builder.addCase(getBlog.pending, (state, action) => {
            state.loadingGetBlog = true
        })
        builder.addCase(getBlog.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataBlog = res.data
            }

            state.loadingGetBlog = false
        })

        // create blog
        builder.addCase(createBlog.pending, (state, action) => {
            state.loadingCreateBlog = true
        })
        builder.addCase(createBlog.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                console.log(res)
            }

            state.loadingCreateBlog = false
        })

        // delete blog
        builder.addCase(deleteBlog.pending, (state, action) => {
            state.loadingDeleteBlog = true
        })
        builder.addCase(deleteBlog.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                const updateDataBlog = state.dataBlog.filter(item => item.id !== res.data.blog_id)

                state.dataBlog = updateDataBlog
            }

            state.loadingDeleteBlog = false
        })

        // update blog
        builder.addCase(updateBlog.pending, (state, action) => {
            state.loadingUpdateBlog = true
        })
        builder.addCase(updateBlog.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                const updateBlog = state.dataBlog.map(item => {
                    if (item.id === res.data.id) {
                        return res.data
                    }

                    return item
                })

                state.dataBlog = updateBlog
            }

            state.loadingUpdateBlog = false
        })
    }
})

export const { } = blogSlice.actions
export default blogSlice.reducer