import React, { useEffect, useState } from 'react'
import {
    KR_ADMIN_ACCOUNT_CODE_FORM,
    KR_USER_EMAIL_MYWALET,
    KR_USER_FIX_DEPOSIT,
    KR_USER_TELEGRAM_MYWALET,
    KR_USER_TITLE_MYWALET
} from '../../../translations/kor'
import CompanyItem from '../../../components/user/CompanyItem'
import ButtonPrimary from '../../../components/ButtonPrimary'
import ConfirmEmail from '../../../sections/user/ConfirmEmail'
import ConfirmCodePin from '../../../sections/user/ConfirmCodePin'
import { useDispatch, useSelector } from 'react-redux'
import { getMyAccount } from '../../../reduxs/actions/auth.action'
import { useParams } from 'react-router-dom'
import Loading from '../../../components/Loading'
import ConfirmTelegram from '../../../sections/user/ConfirmTelegram'

export default function MyWallet() {
    const dispatch = useDispatch()

    const { game } = useParams()

    const { loadGetMyAccount, dataMyAccount } = useSelector(state => state.auth)

    const [isShowChangeEmail, setIsChangeEmail] = useState(false)
    const [isShowChangeCodePin, setIsChangeCodePin] = useState(false)
    const [isShowChangeTele, setIsChangeTele] = useState(false)

    /// email
    const handleShowChangeEmail = () => {
        setIsChangeEmail(!isShowChangeEmail)
    }

    /// code pin
    const handleShowChangeCodePin = () => {
        setIsChangeCodePin(!isShowChangeCodePin)
    }

    /// code telegram
    const handleShowChangeTelegram = () => {
        setIsChangeTele(!isShowChangeTele)
    }

    useEffect(() => {
        dispatch(getMyAccount(game))
    }, [dispatch, game])

    if (loadGetMyAccount || !dataMyAccount) {
        return <Loading />
    }

    const { gamepoint, token, user } = dataMyAccount

    return (
        <>
            <h2 className='text-text14-20 font-bold'>{KR_USER_TITLE_MYWALET}</h2>

            <section className='rounded-16 bg-white text-black p-5 flex flex-col gap-4 items-center flex-grow'>
                <div className='bg-bg-point bg-no-repeat bg-cover p-5 rounded-16 gap-2.5 flex flex-col justify-between w-full text-white'>
                    <p className='text-text14-20'>{gamepoint.gamepoint_name}</p>

                    <p className='text-text20-24 font-bold'>100 {gamepoint.gamepoint_name} = {gamepoint.rate} USDT</p>
                </div>

                <div className='grid gap-2 w-full'>
                    {
                        token.map(item =>
                            <CompanyItem
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                address={item.token_address}
                                logo={item.logoToken}
                            />)
                    }
                </div>

                <div className='w-full'>
                    <h3 className='text-text14-20 font-bold mb-2'>{KR_USER_TELEGRAM_MYWALET}</h3>

                    <div className='flex items-center justify-between p-3 rounded-8 bg-lightBlue'>
                        <p className='text-text14-20'>{user.telegram_id}</p>

                        <ButtonPrimary title={KR_USER_FIX_DEPOSIT} className='!text-text10-12 text-white' onClick={handleShowChangeTelegram} />
                    </div>
                </div>

                <div className='w-full'>
                    <h3 className='text-text14-20 font-bold mb-2'>{KR_USER_EMAIL_MYWALET}</h3>

                    <div className='flex items-center justify-between p-3 rounded-8 bg-lightBlue'>
                        <p className='text-text14-20'>{user.email}</p>

                        <ButtonPrimary title={KR_USER_FIX_DEPOSIT} className='!text-text10-12 text-white' onClick={handleShowChangeEmail} />
                    </div>
                </div>

                <div className='w-full'>
                    <h3 className='text-text14-20 font-bold mb-2'>{KR_ADMIN_ACCOUNT_CODE_FORM}</h3>

                    <div className='flex items-center justify-between p-3 rounded-8 bg-lightBlue'>
                        <input className='text-text14-20 bg-transparent' defaultValue='1234' type='password' />

                        <ButtonPrimary title={KR_USER_FIX_DEPOSIT} className='!text-text10-12 text-white' onClick={handleShowChangeCodePin} />
                    </div>
                </div>

                {/* <ConfirmPassword handleShow={handleShowChangePass} show={isShowChangePass} /> */}
                <ConfirmEmail handleShow={handleShowChangeEmail} show={isShowChangeEmail} />
                <ConfirmCodePin handleShow={handleShowChangeCodePin} show={isShowChangeCodePin} />
                <ConfirmTelegram handleShow={handleShowChangeTelegram} show={isShowChangeTele} />
            </section>
        </>
    )
}
