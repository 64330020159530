import React from 'react'
import { KR_ADMIN_DEPOSIT_DASHBOARD, KR_ADMIN_POINT_DASHBOARD, KR_ADMIN_WITHDRAW_DASHBOARD } from '../../../translations/kor'

export default function TotalPoint({ data }) {
    return (
        <section className='flex gap-4'>
            <div className='bg-bg-deposit bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col gap-4'>
                <p className='text-text16-26'>{KR_ADMIN_DEPOSIT_DASHBOARD}</p>

                <p className='text-text40-50 font-bold'>{data.deposit} {KR_ADMIN_POINT_DASHBOARD}</p>
            </div>

            <div className='bg-bg-withdraw bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col gap-4'>
                <p className='text-text16-26'>{KR_ADMIN_WITHDRAW_DASHBOARD}</p>

                <p className='text-text40-50 font-bold'>{data.withdrawal} {KR_ADMIN_POINT_DASHBOARD}</p>
            </div>
        </section>
    )
}
