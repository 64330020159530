import { createSlice } from "@reduxjs/toolkit";
import { changeCodePassword, changeEmail, changeTelegram, checkGamesiteUser, confirmAppPassword, confirmPasswordNonToken, findAccount, getAccountAdmin, getInforAccount, getInforLogin, getMyAccount, login, logout, updateInforAdmin, updateInforLogin, updateToken, updateWaringAdmin } from "../actions/auth.action";
import { clearLocalStorage, getLocalStorage } from "../../utils/localStorage";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        nameUser: null,
        email: null,
        userRole: null,

        isAuthentication: false,
        loadingLogin: false,

        loadingLogout: false,

        loadGetMyAccount: false,
        dataMyAccount: false,

        loadingFindAccount: false,

        loadingGetInforLogin: false,
        dataInforAccountLogin: null,

        loadingUpdateInforLogin: false,
        loadingConfirmPasswordNonToken: false,

        loadingConfirmPassword: false,

        loadingChangeEmail: false,

        loadingChangeTelegram: false,

        loadingChangeCodePassword: false,

        loadingGetAccountAdmin: false,
        dataAccountAdmin: undefined,

        loadingUpdateWaringAdmin: false,

        loadingCheckGamesiteUser: false,
        gamesiteCheck: '',

        loadingUpdateInforAdmin: false,

        loadingUpdateToken: false,
    },
    reducers: {
        checkAuth(state, action) {
            const token = getLocalStorage('token')
            const role = getLocalStorage('role')

            state.isAuthentication = token ? true : false
            state.userRole = (token && role) ? role : null
        }
    },
    extraReducers: builder => {
        // login
        builder.addCase(login.pending, (state, action) => {
            state.loadingLogin = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.isAuthentication = true
                state.userRole = res.data.role
            }

            state.loadingLogin = false
        })

        // get infor account
        builder.addCase(getInforAccount.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.nameUser = res.data.name
                state.email = res.data.email
                state.userRole = res.data.role
            }
        })

        // get account
        builder.addCase(getMyAccount.pending, (state, action) => {
            state.loadGetMyAccount = true
        })
        builder.addCase(getMyAccount.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataMyAccount = res.data
            }

            state.loadGetMyAccount = false
        })

        // logout
        builder.addCase(logout.pending, (state, action) => {
            state.loadingLogout = true
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.isAuthentication = false
                state.email = null
                state.userRole = null

                clearLocalStorage()
            }

            state.loadingLogout = false
        })

        // find account
        builder.addCase(findAccount.pending, (state, action) => {
            state.loadingFindAccount = true
        })
        builder.addCase(findAccount.fulfilled, (state, action) => {
            state.loadingFindAccount = false
        })

        // get infor after login
        builder.addCase(getInforLogin.pending, (state, action) => {
            state.loadingGetInforLogin = true
        })
        builder.addCase(getInforLogin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataInforAccountLogin = res.data
            }

            state.loadingGetInforLogin = false
        })

        // update infor after login
        builder.addCase(updateInforLogin.pending, (state, action) => {
            state.loadingUpdateInforLogin = true
        })
        builder.addCase(updateInforLogin.fulfilled, (state, action) => {
            state.loadingUpdateInforLogin = false
        })

        // confirm password
        builder.addCase(confirmPasswordNonToken.pending, (state, action) => {
            state.loadingConfirmPasswordNonToken = true
        })
        builder.addCase(confirmPasswordNonToken.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.isAuthentication = true
                state.userRole = res.data.role
            }

            state.loadingConfirmPasswordNonToken = false
        })

        // confirm password
        builder.addCase(confirmAppPassword.pending, (state, action) => {
            state.loadingConfirmPassword = true
        })
        builder.addCase(confirmAppPassword.fulfilled, (state, action) => {
            state.loadingConfirmPassword = false
        })

        // change email
        builder.addCase(changeEmail.pending, (state, action) => {
            state.loadingChangeEmail = true
        })
        builder.addCase(changeEmail.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataMyAccount = {
                    ...state.dataMyAccount,
                    user: {
                        ...state.dataMyAccount.user,
                        email: res.data.new_email
                    }
                }
            }

            state.loadingChangeEmail = false
        })

        // change telegram
        builder.addCase(changeTelegram.pending, (state, action) => {
            state.loadingChangeTelegram = true
        })
        builder.addCase(changeTelegram.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataMyAccount = {
                    ...state.dataMyAccount,
                    user: {
                        ...state.dataMyAccount.user,
                        telegram_id: res.data.new_telegram
                    }
                }
            }

            state.loadingChangeTelegram = false
        })

        // change Code Password
        builder.addCase(changeCodePassword.pending, (state, action) => {
            state.loadingChangeCodePassword = true
        })
        builder.addCase(changeCodePassword.fulfilled, (state, action) => {
            state.loadingChangeCodePassword = false
        })

        // get account admin
        builder.addCase(getAccountAdmin.pending, (state, action) => {
            state.loadingGetAccountAdmin = true
        })
        builder.addCase(getAccountAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataAccountAdmin = res.data
            }

            state.loadingGetAccountAdmin = false
        })

        // update warning 
        builder.addCase(updateWaringAdmin.pending, (state, action) => {
            state.loadingUpdateWaringAdmin = true
        })
        builder.addCase(updateWaringAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                console.log(res)
            }

            state.loadingUpdateWaringAdmin = false
        })

        // check gamesite user
        builder.addCase(checkGamesiteUser.pending, (state, action) => {
            state.loadingCheckGamesiteUser = true
        })
        builder.addCase(checkGamesiteUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.gamesiteCheck = res.data.namesite
            }

            state.loadingCheckGamesiteUser = false
        })

        // update infor admin
        builder.addCase(updateInforAdmin.pending, (state, action) => {
            state.loadingUpdateInforAdmin = true
        })
        builder.addCase(updateInforAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.gamesiteCheck = res.data.namesite
                state.dataAccountAdmin.user = {
                    ...state.dataAccountAdmin.user,
                    ...res.data
                }
            }

            state.loadingUpdateInforAdmin = false
        })

        // update token
        builder.addCase(updateToken.pending, (state, action) => {
            state.loadingUpdateToken = true
        })
        builder.addCase(updateToken.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {

                const updateDataAccount = state.dataAccountAdmin.token.map(item => {
                    if (parseInt(item.id) === parseInt(res.data.token_id)) {
                        return {
                            ...item,
                            token_address: res.data.address
                        }
                    }

                    return item
                })

                state.dataAccountAdmin = {
                    ...state.dataAccountAdmin,
                    token: updateDataAccount
                }
            }

            state.loadingUpdateToken = false
        })
    }
})

export const { checkAuth } = authSlice.actions
export default authSlice.reducer
