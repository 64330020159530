import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

// get deposits 
export const getdeposit = createAsyncThunk(
    'deposit/get',
    async (game) => {
        const response = await get(`/getDeposit?namesite=${game}`)

        return response
    }
)

// create deposits 
export const createdeposit = createAsyncThunk(
    'deposit/create',
    async (datas) => {
        const { game, data } = datas

        const response = await post(`/createDeposit?namesite=${game}`, data)

        return response
    }
)
