import React, { useEffect, useState } from 'react'
import { PopupAdmin } from './user/Popup'
import { ERROR_REQUIRE, KR_ADMIN_CREATE_USER } from '../translations/kor'
import LabelForm from './LabelForm'
import InputForm from './InputForm'
import { useFormik } from 'formik'
import ButtonSecondary from './ButtonSecondary'
import ButtonPrimary from './ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import { createUser } from '../reduxs/actions/user.action'
import * as Yup from 'yup'
import { getLevels } from '../reduxs/actions/Level.action'
import OptionForm from './OptionForm'
import { getAllGameSite } from '../reduxs/actions/gamesite.action'
import renderError from './RenderError'

export default function CreateNewUser({ handleShow, show }) {
    const dispatch = useDispatch()

    const { dataLevel } = useSelector(state => state.level)
    const { dataGamesite } = useSelector(state => state.gamesite)

    const [isError, setIsError] = useState()

    const formik = useFormik({
        initialValues: {
            id: '',
            password: '',
            level: '',
            email: '',
            phone: '',
            telegram: '',
            site: ''
        },
        validate: values => {
            const error = {}

            if ((values.level === 1) && !(values.site)) {
                error.site = ERROR_REQUIRE
            }

            return error
        },
        validationSchema: Yup.object({
            id: Yup.string().required(ERROR_REQUIRE),
            password: Yup.string().required(ERROR_REQUIRE),
            level: Yup.string().required(ERROR_REQUIRE),
            //email: Yup.string().required(ERROR_REQUIRE),
            email: Yup.string(),
            phone: Yup.string().required(ERROR_REQUIRE),
            telegram: Yup.string().required(ERROR_REQUIRE),
            site: Yup.string()
        }),
        onSubmit: async values => {
            const result = await dispatch(createUser(values))

            const res = result.payload

            if (res.status === 1) {
                handleShow()
            } else {
                setIsError(res.code)
            }
        }
    })

    const handleSelectSite = (site) => {
        formik.setFieldValue('site', site)
    }

    const handleSelectLevel = (level) => {
        formik.setFieldValue('level', level)
    }

    useEffect(() => {
        dispatch(getLevels())
        dispatch(getAllGameSite())
    }, [])

    return (
        <PopupAdmin handleShow={handleShow} show={show} className='flex flex-col items-center gap-5'>
            <h3 className='text-text30-40 font-bold'>{KR_ADMIN_CREATE_USER}</h3>

            <form className='w-full flex flex-col gap-4 text-white' onSubmit={formik.handleSubmit}>
                {
                    isError
                    &&
                    <span>{renderError(isError)}</span>
                }
                <div>
                    <LabelForm label='ID' htmlFor='id' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='id'
                        onChange={formik.handleChange}
                        value={formik.values.id}
                        placeholder={'Enter ID'}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        error={(formik.touched.id && formik.errors.id) && formik.errors.id}
                    />
                </div>
                <div>
                    <LabelForm label='Password' htmlFor='password' className='!text-text14-20' />
                    <InputForm
                        type='password'
                        name='password'
                        max={4}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        placeholder={'Enter Password'}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        error={(formik.touched.password && formik.errors.password) && formik.errors.password}
                    />
                </div>
                <div>
                    <LabelForm label='Level' htmlFor='level' className='!text-text14-20' />
                    <OptionForm
                        placeholder={formik.values.level ? dataLevel.filter(item => item.id === formik.values.level)[0].name : 'Level'}
                        data={dataLevel}
                        handleSelect={handleSelectLevel}
                        isSelected={formik.values.level}
                        error={(formik.touched.level && formik.errors.level) && formik.errors.level}
                    />
                </div>
                <div>
                    <LabelForm label='Phone' htmlFor='phone' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='phone'
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        placeholder={'Enter Phone'}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        error={(formik.touched.phone && formik.errors.phone) && formik.errors.phone}
                    />
                </div>
                <div>
                    <LabelForm label='Email' htmlFor='email' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placeholder={'Enter Email'}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                    />
                </div>
                <div>
                    <LabelForm label='Telegram' htmlFor='telegram' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='telegram'
                        onChange={formik.handleChange}
                        value={formik.values.telegram}
                        placeholder={'Enter Telegram'}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        error={(formik.touched.telegram && formik.errors.telegram) && formik.errors.telegram}
                    />
                </div>


                {
                    formik.values.level === 1
                    &&
                    <div>
                        <LabelForm label='Site' htmlFor='site' className='!text-text14-20' />
                        <OptionForm
                            placeholder={formik.values.site ? dataGamesite.filter(item => item.id === formik.values.site)[0].name : 'Site'}
                            data={dataGamesite}
                            handleSelect={handleSelectSite}
                            top
                            isSelected={formik.values.site}
                            error={(formik.touched.site && formik.errors.site) && formik.errors.site}
                        />
                    </div>
                }

                <div className='grid grid-cols-2 gap-2'>
                    <ButtonSecondary title='취소' onClick={handleShow} />
                    <ButtonPrimary title='확인' onClick={formik.handleSubmit} type='submit' />
                </div>
            </form>
        </PopupAdmin>
    )
}
