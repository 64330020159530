import { createSlice } from "@reduxjs/toolkit";
import { getAllWallet } from "../actions/wallet.action";

const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        loadingGetWallet: false,
        dataWallet: undefined
    },
    extraReducers: builder => {
        // get all wallet
        builder.addCase(getAllWallet.pending, (state, action) => {
            state.loadingGetWallet = true
        })
        builder.addCase(getAllWallet.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataWallet = res.data
            }

            state.loadingGetWallet = false
        })
    }
})

export const { } = walletSlice.actions
export default walletSlice.reducer
