import React, { useState } from 'react'
import InputForm from '../../../components/InputForm'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRE, KR_USER_PLACERHODER_LOGIN, KR_USER_SUBMIT_LOGIN } from '../../../translations/kor'
import { useDispatch } from 'react-redux'
import { findAccount } from '../../../reduxs/actions/auth.action'
import renderError from '../../../components/RenderError'

export default function LoginUser({ nameGamesite }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isError, setIsError] = useState()
    const [searchParams, setSearchParams] = useSearchParams()

    const nameuser = searchParams.get('nameuser')

    const formik = useFormik({
        initialValues: {
            id: nameuser
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            id: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const response = await dispatch(findAccount(values))

            if (response.payload.status === 1) {
                const result = response.payload.data.find_status

                return result === 1 ? navigate(`pin-code/${values.id}`) : navigate(`confirm/${values.id}`)
            } else {
                setIsError(response.payload.code)
            }
        }
    })

    return (
        <form className='w-mobile bg-lightGray py-10 px-5 rounded-24 text-white flex flex-col items-center gap-[30px]' onSubmit={formik.handleSubmit}>
            <h2 className='font-bold'>GameWallet {nameGamesite}</h2>

            <InputForm
                name='id'
                value={formik.values.id}
                onChange={(e) => {
                    setIsError(false)
                    formik.handleChange(e)
                }}
                className='!py-1.5 !px-3 !text-text14-20'
                placeholder={KR_USER_PLACERHODER_LOGIN}
                error={((formik.touched.id && formik.errors.id) || isError) && (renderError(isError) || formik.errors.id)}
                forcus={true}
            />

            <ButtonPrimary title={KR_USER_SUBMIT_LOGIN} className='w-[159px] !py-1 !px-3' onClick={formik.handleSubmit} />
        </form>
    )
}

