import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

export const getBlog = createAsyncThunk(
    'blog/getall',
    async () => {
        const response = await get('admin/listblog')

        return response
    }
)

// create blog
export const createBlog = createAsyncThunk(
    'blog/create',
    async (data) => {
        const response = await post('admin/createBlog', data)

        return response
    }
)

// delete blog
export const deleteBlog = createAsyncThunk(
    'blog/delete',
    async (data) => {
        const response = await post('admin/deleteBlog', data)

        return response
    }
)

// update blog
export const updateBlog = createAsyncThunk(
    'blog/update',
    async (data) => {
        const response = await post('admin/updateBlog', data)

        return response
    }
)