import React, { useEffect } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import DashboardAdmin from './DashboardAdmin'
import SidebarAdmin from '../../components/admin/SidebarAdmin'
import HeaderAdmin from '../../components/admin/HeaderAdmin'
import { checkAuth } from '../../reduxs/reducers/auth.reducer'
import { useDispatch } from 'react-redux'
import ProtectRouterAdmin from '../../components/ProtectRouterAdmin'

export default function AdminPage() {
    const dispatch = useDispatch()

    const { pathname } = useLocation()
    const { game } = useParams()

    useEffect(() => {
        dispatch(checkAuth())
    }, [dispatch])

    return (
        <div className='flex text-white w-full'>
            <SidebarAdmin />

            <main className='w-10/12 flex flex-col h-screen overflow-y-scroll pb-5'>
                <HeaderAdmin />

                <article className='px-5 flex flex-col gap-5'>
                    {
                        (pathname === `/admin/${game}` || pathname === '/admin')
                            ?
                            <ProtectRouterAdmin>
                                <DashboardAdmin />
                            </ProtectRouterAdmin>
                            :
                            <Outlet />
                    }
                </article>
            </main>
        </div>
    )
}
