import { Notification } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import { KR_ADMIN_NOTIFY_LOGIN, KR_USER_ALL_TRANSACTION, KR_USER_IN_TRANSACTION, KR_USER_OUT_TRANSACTION } from '../../translations/kor'
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotifycation } from '../../reduxs/actions/notifycation.action';
import { Link, useParams } from 'react-router-dom';
import { updateNotifycation } from '../../reduxs/reducers/notifycation.reducer';
import audio from '../../assets/notify/notifycation1.mp3'

export default function Notifycation() {
    const dispatch = useDispatch()

    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)
    const [isNewNotify, setIsNewNotify] = useState(false)

    const { game } = useParams()

    const [isSelectFilter, setIsSelectFilter] = useState(0)

    // filter status
    const handleSelectFilter = (filter) => {
        setIsSelectFilter(filter)
    }

    const notifyAudio = new Audio(audio)

    const { loadingGettNotifycation, dataNotifycation, isRead } = useSelector(state => state.notify)

    const handleShow = () => {
        setIsShow(!isShow)
        setIsNewNotify(false)
    }

    useEffect(() => {
        Pusher.logToConsole = true;

        const pusher = new Pusher('c4f25e7ba3e605bbe7d5', {
            cluster: 'ap1'
        });

        const channel = pusher.subscribe(`my-channel${game}`);
        channel.bind('my-event', function (data) {
            dispatch(updateNotifycation(data.message))
            notifyAudio.play().catch((error) => {
                console.log(error)
            })
            setIsNewNotify(true)
        });

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, []);

    useEffect(() => {
        dispatch(getAllNotifycation())
    }, [])

    useEffect(() => {
        setIsNewNotify(isRead)
    }, [isRead])

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <div ref={contentRef} className='relative'>
            <button className='relative' onClick={handleShow}>
                <Notification className='lg:w-6 w-4' />
            </button>

            {
                isNewNotify ? <span className='absolute top-0 right-0 w-2.5 rounded-full aspect-square bg-[#27B73E]'></span> : ''
            }

            {
                isShow
                &&
                <div className='absolute z-50 top-full mt-1 right-0 w-[400px] aspect-square overflow-y-auto hiddenScroll bg-white rounded-12 text-black shadow-shadow-notify'>
                    <div className='p-5 border-b border-[#EFEFEF]'>
                        <h4 className='lg:text-text20-40 font-bold'>{KR_ADMIN_NOTIFY_LOGIN}</h4>

                        <div className='grid grid-cols-3 gap-2.5'>
                            <button type='button' onClick={() => handleSelectFilter(0)} className={`text-text16-26 py-0.5 px-4 rounded-8 hover:bg-blue hover:text-white duration-300 ${isSelectFilter === 0 ? 'bg-blue text-white' : ''}`}>{KR_USER_ALL_TRANSACTION}</button>
                            <button type='button' onClick={() => handleSelectFilter(1)} className={`text-text16-26 py-0.5 px-4 rounded-8 hover:bg-blue hover:text-white duration-300 ${isSelectFilter === 1 ? 'bg-blue text-white' : ''}`}>{KR_USER_IN_TRANSACTION}</button>
                            <button type='button' onClick={() => handleSelectFilter(2)} className={`text-text16-26 py-0.5 px-4 rounded-8 hover:bg-blue hover:text-white duration-300 ${isSelectFilter === 2 ? 'bg-blue text-white' : ''}`}>{KR_USER_OUT_TRANSACTION}</button>
                        </div>
                    </div>

                    <div className='p-5 grid gap-2.5'>
                        {
                            (loadingGettNotifycation || !dataNotifycation)
                                ?
                                <p className='text-text14-20 text-center'>Loading...</p>
                                :
                                dataNotifycation.map(notify => {
                                    if (!isSelectFilter || (notify.type === isSelectFilter)) {
                                        return <NotifyItem
                                            key={notify.id}
                                            item={notify}
                                        />
                                    }
                                })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

const NotifyItem = (props) => {
    const { content, read, time, username } = props.item

    return (
        <Link to={`#`} className='flex justify-between items-start gap-10'>
            <div>
                <p className='text-text16-20'>
                    <span className='font-bold'>{username}</span> {content}
                </p>
                <span className='text-deactivate text-text14-20'>{time}</span>
            </div>

            <div>
                {
                    read && <div className='w-2.5 rounded-full aspect-square bg-green-600 mt-2.5'></div>
                }
            </div>
        </Link>
    )
}
