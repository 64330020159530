import React, { useEffect } from 'react'
import { KR_ADMIN_DASHBOARD_SIDEBAR } from '../../translations/kor'
import TotalPoint from '../../sections/admin/Dashboard/TotalPoint'
import LastTransaction from '../../sections/admin/Dashboard/LastTransaction'
import TitlePage from '../../components/admin/TitlePage'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardAdmin } from '../../reduxs/actions/dashboard.action'
import { useParams } from 'react-router-dom'
import Loading from '../../components/Loading'

export default function DashboardAdmin() {
    const dispatch = useDispatch()

    const { game } = useParams()

    const { loadingDashboardAdmin, dataDashboardAdmin } = useSelector(state => state.dashboard)

    useEffect(() => {
        dispatch(getDashboardAdmin((game || '')))
    }, [dispatch, game])

    if (loadingDashboardAdmin || !dataDashboardAdmin) {
        return <Loading />
    }

    const { history_transaction, total_transaction } = dataDashboardAdmin

    return (
        <>
            <TitlePage title={KR_ADMIN_DASHBOARD_SIDEBAR} />

            <TotalPoint data={total_transaction} />

            <LastTransaction data={history_transaction} />
        </>
    )
}
