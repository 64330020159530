import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import {
    KR_ADMIN_ACCOUNT_SIDEBAR,
    KR_ADMIN_BANNER_SIDEBAR,
    KR_ADMIN_BLOG_SIDEBAR,
    KR_ADMIN_DASHBOARD_SIDEBAR,
    KR_ADMIN_GENERAL_SIDEBAR,
    KR_ADMIN_HISTORY_SIDEBAR,
    KR_ADMIN_LOGOUT_SIDEBAR,
    KR_ADMIN_SETTING_SIDEBAR,
    KR_ADMIN_USER_SIDEBAR,
    KR_ADMIN_WALLET_SIDEBAR,
    LOGO
} from '../../translations/kor'
import { IoGridOutline, IoLogOutOutline, IoPeopleOutline, IoWalletOutline } from 'react-icons/io5'
import { BsBank, BsMenuButton } from 'react-icons/bs'
import { IoIosGlobe } from 'react-icons/io'
import { FaRegUser } from 'react-icons/fa'
import { logout } from '../../reduxs/actions/auth.action'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalStorage } from '../../utils/localStorage'

export default function SidebarAdmin() {
    const dispatch = useDispatch()

    const { userRole } = useSelector(state => state.auth)
    const role = getLocalStorage('role')

    const { game } = useParams()

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <aside className='w-2/12 flex-shrink-0 bg-darkblue py-5 px-[30px] flex flex-col gap-10'>
            <h1>
                <Link to={game ? `/admin/${game}` : '/admin'} className='text-text20-40 font-bold'>{LOGO}</Link>
            </h1>

            <div className='flex-grow flex flex-col justify-between'>
                <div className='flex flex-col gap-10'>
                    <div className='flex flex-col gap-4'>
                        <h4 className='text-text16-20 font-bold'>{KR_ADMIN_GENERAL_SIDEBAR}</h4>

                        <ul className='flex flex-col gap-2'>
                            <NavItem icon={<IoGridOutline />} title={KR_ADMIN_DASHBOARD_SIDEBAR} link={game ? `/admin/${game}` : '/admin'} end={true} />
                            <NavItem icon={<IoWalletOutline />} title={KR_ADMIN_WALLET_SIDEBAR} link={`wallets`} />
                            <NavItem icon={<BsBank />} title={KR_ADMIN_HISTORY_SIDEBAR} link='historys' />
                            <NavItem icon={<IoPeopleOutline />} title={KR_ADMIN_USER_SIDEBAR} link='users' />
                            {
                                (userRole || role) === 'total-admin'
                                &&
                                <>
                                    <NavItem icon={<IoIosGlobe />} title={KR_ADMIN_BANNER_SIDEBAR} link='banners' />
                                    <NavItem icon={<BsMenuButton />} title={KR_ADMIN_BLOG_SIDEBAR} link='blogs' />
                                </>
                            }
                        </ul>
                    </div>

                    <div className='flex flex-col gap-4'>
                        <h4 className='text-text16-20 font-bold'>{KR_ADMIN_SETTING_SIDEBAR}</h4>

                        <ul>
                            <NavItem icon={<FaRegUser />} title={KR_ADMIN_ACCOUNT_SIDEBAR} link='my-account' />
                        </ul>
                    </div>
                </div>

                <button onClick={handleLogout} className='flex items-center gap-2 text-text16-26 py-3 px-4 rounded-12 duration-300 hover:bg-blue'>
                    <IoLogOutOutline />

                    <span>{KR_ADMIN_LOGOUT_SIDEBAR}</span>
                </button>
            </div>
        </aside>
    )
}

export const NavItem = ({ icon, title, link = '#', end = false }) => {
    const style = 'flex items-center gap-2 text-text16-26 py-3 px-4 rounded-12 duration-300 hover:bg-blue'

    return (
        <li>
            <NavLink to={link} className={({ isActive, isPending }) => isActive ? `${style} bg-blue` : `${style}`} end={end}>
                {icon}

                <span>{title}</span>
            </NavLink>
        </li>
    )
}
