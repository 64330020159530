import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

export const getBanners = createAsyncThunk(
    'banner/getall',
    async () => {
        const response = await get('admin/listbanner')

        return response
    }
)

// create banner
export const createBanners = createAsyncThunk(
    'banner/create',
    async (data) => {
        const response = await post('admin/createBanner', data)

        return response
    }
)

// delete banner
export const deleteBanners = createAsyncThunk(
    'banner/delete',
    async (data) => {
        const response = await post('admin/deleteBanner', data)

        return response
    }
)

// update banner
export const updateBanners = createAsyncThunk(
    'banner/update',
    async (data) => {
        const response = await post('admin/updateBanner ', data)

        return response
    }
)