import React from 'react'
import { Link } from 'react-router-dom'
import { URL_SERVER_IMAGE } from '../../utils/api'

export default function ListAdvSection({ data }) {
    return (
        <section>
            <ul className='flex flex-col gap-4'>
                {
                    data.map(item => <AdvItem key={item.id} image={item.address_banner} link={item.link} />)
                }
            </ul>
        </section>
    )
}

export const AdvItem = ({ id, image, link }) => {
    return (
        <li>
            <Link to={link} className='' target='_blank'>
                <img src={`${URL_SERVER_IMAGE}${image}`} alt='banner' className='aspect-[3/1] rounded-16 object-cover' />
            </Link>
        </li>
    )
}