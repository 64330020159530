import React, { useState } from 'react'
import Popup from '../../components/user/Popup'
import ButtonPrimary from '../../components/ButtonPrimary'
import { ERROR_REQUIRE, KR_USER_CONFIRM_DEPOSIT, KR_USER_CONFIRMID_NOTIFY_DEPOSIT, KR_USER_OFF_DEPOSIT, KR_USER_TITLE_CONFIRMID_DEPOSIT } from '../../translations/kor'
import ButtonSecondary from '../../components/ButtonSecondary'
import InputForm from '../../components/InputForm'
import { PopupConfirmCodePin } from '../../components/user/PopupConfirmCodePin'
import { useFormik } from 'formik'
import { confirmAppPassword } from '../../reduxs/actions/auth.action'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { createdeposit } from '../../reduxs/actions/deposit.action'
import NotifyComponent from '../../components/NotifyComponent'

export default function ConfirmIdTransaction({ show, handleShow, dataSubmit }) {
    const dispatch = useDispatch()

    const [isConfirm, setIsConfirm] = useState(false)
    const [isError, setIsError] = useState()

    const formik = useFormik({
        initialValues: {
            app_password: ''
        },
        validationSchema: Yup.object({
            app_password: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(confirmAppPassword(values))

            if (result.payload.status === 1) {
                setIsConfirm(true)
            } else {
                setIsError(result.payload.code)
            }
        }
    })

    return (
        <>
            <Popup show={show} handleShow={handleShow}>
                {
                    isConfirm
                        ?
                        <PopupConfirmId handleShow={handleShow} dataSubmit={dataSubmit} />
                        :
                        <PopupConfirmCodePin formik={formik} isError={isError} setIsError={setIsError} />
                }
            </Popup>
        </>
    )
}

const PopupConfirmId = ({ handleShow, dataSubmit }) => {
    const dispatch = useDispatch()
    const { game } = useParams()

    const navigate = useNavigate()

    const [isNotify, setIsNotify] = useState()

    const formik = useFormik({
        initialValues: {
            'TXID/HASH': ''
        },
        validationSchema: Yup.object({
            'TXID/HASH': Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const data = ({
                data: {
                    ...dataSubmit.values,
                    ...values
                },
                game: game
            })

            const result = await dispatch(createdeposit(data))

            const res = result.payload

            if (res.status === 1) {
                setIsNotify('전송이  확인되었습니다.')

                setTimeout(() => {
                    navigate(`/user/${game}/transaction`)
                }, 2000);
            }
        }
    })

    return (
        <>
            <NotifyComponent notify={isNotify} />

            <form className='flex flex-col items-center gap-4' onSubmit={formik.handleSubmit}>
                <h3 className='font-bold'>{KR_USER_TITLE_CONFIRMID_DEPOSIT}</h3>

                <InputForm
                    value={formik.values['TXID/HASH']}
                    name='TXID/HASH'
                    onChange={formik.handleChange}
                    placeholder='전송 ID (TXID)를 입력'
                    error={(formik.touched['TXID/HASH'] && formik.errors['TXID/HASH']) && formik.errors['TXID/HASH']}
                />
                <span className='text-red text-text10-12'>{KR_USER_CONFIRMID_NOTIFY_DEPOSIT}</span>

                <div className='grid grid-cols-2 w-full gap-2'>
                    <ButtonSecondary title={KR_USER_OFF_DEPOSIT} className='text-white' onClick={handleShow} />
                    <ButtonPrimary type='submit' title={KR_USER_CONFIRM_DEPOSIT} className='text-white' onClick={formik.handleSubmit} />
                </div>
            </form>
        </>
    )
}