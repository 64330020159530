import React, { useEffect } from 'react'
import defaultAvatar from '../../../assets/images/avatarpng.png'
import { KR_USER_HELLO_DASHBOARD } from '../../../translations/kor'
import { Link, useParams } from 'react-router-dom'
import { CardReceive, Grid2, ReceiveSquare2, TransmitSqaure2 } from 'iconsax-react'
import ListAdvSection from '../../../sections/user/ListAdvSection'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardUser } from '../../../reduxs/actions/dashboard.action'
import { getInforAccount } from '../../../reduxs/actions/auth.action'
import ListNotifyDashboard from '../../../sections/user/ListNotifyDashboard'
import Loading from '../../../components/Loading'
import WarningDashboard from '../../../sections/user/WarningDashboard'

const ListLink = [
    {
        id: 1,
        icon: <TransmitSqaure2 size='20' variant="Outline" />,
        link: 'deposit',
        title: '입금'
    },
    {
        id: 2,
        icon: <ReceiveSquare2 size='20' variant="Outline" />,
        link: 'withdraw',
        title: '출금'
    },
    {
        id: 3,
        icon: <CardReceive size='20' variant="Outline" />,
        link: 'transaction',
        title: '전송내역'
    },
    {
        id: 4,
        icon: <Grid2 size='20' variant="Outline" />,
        link: '',
        title: '기타'
    },
]

export default function DashboardUserPage() {
    const dispatch = useDispatch()

    const { game } = useParams()

    const { loadingDashboard, dataDashboard } = useSelector(state => state.dashboard)
    const { nameUser } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(getInforAccount())
    }, [dispatch])

    useEffect(() => {
        dispatch(getDashboardUser(game))
    }, [dispatch, game])

    if (loadingDashboard || !dataDashboard || !nameUser) {
        return <Loading />
    }

    const { string_notification, gamepoint, banner, warning_notfication } = dataDashboard

    return (
        <>
            <section className='flex flex-col gap-4'>
                <div className='flex gap-4 items-start'>
                    <img src={defaultAvatar} alt='avatar' className='w-9 h-9 rounded-full object-contain' />

                    <div className=''>
                        <span className='text-text10-12 block mb-0.5'>{KR_USER_HELLO_DASHBOARD}</span>
                        <h5 className='text-text14-20 font-bold'>{nameUser}</h5>
                    </div>
                </div>

                <div className='bg-bg-point bg-no-repeat bg-cover p-5 rounded-16 gap-2.5 flex flex-col justify-between'>
                    <p className='text-text14-20'>{gamepoint.gamepoint_name}</p>

                    <p className='text-text20-24 font-bold'>100 {gamepoint.gamepoint_name} = {gamepoint.rate} USDT</p>
                </div>

                <ul className='flex gap-2'>
                    {
                        ListLink.map(item => <NavItem key={item.id} icon={item.icon} title={item.title} link={item.link} />)
                    }
                </ul>
            </section>

            <ListNotifyDashboard data={string_notification} />

            <WarningDashboard data={warning_notfication} />

            <ListAdvSection data={banner} />
        </>
    )
}

export const NavItem = ({ icon, title, link = '#' }) => {
    return (
        <li className='w-full flex justify-center items-center bg-darkblue rounded-12 hover:bg-blue cursor-pointer duration-300'>
            <Link to={link} className='flex flex-col items-center gap-1 p-2 w-full'>
                {icon}

                <span className='inline-block text-text10-12'>
                    {title}
                </span>
            </Link>
        </li>
    )
}