import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

// login
export const login = createAsyncThunk(
    'auth/login',
    async (data) => {
        const response = await post('/auth/login', data)

        return response
    }
)

// register
export const register = createAsyncThunk(
    'auth/register',
    async (data) => {
        const response = await post('/auth/register', data)

        return response
    }
)

// get infor account
export const getInforAccount = createAsyncThunk(
    'auth/inforAccount',
    async () => {
        const response = await get('auth/user')

        return response
    }
)

// get account page
export const getMyAccount = createAsyncThunk(
    'auth/account',
    async (game) => {
        const response = await get(`user/my-page?namesite=${game}`)

        return response
    }
)

// logout
export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        const response = await get('auth/logout')

        return response
    }
)

// find account
export const findAccount = createAsyncThunk(
    'auth/findAccount',
    async (data) => {
        const response = await post('auth/user/find_account', data)

        return response
    }
)

// get infor after login 
export const getInforLogin = createAsyncThunk(
    'auth/getinforLogin',
    async (id) => {
        const response = await get(`auth/getInforAfterLogin/${id}`)

        return response
    }
)

// get infor after login 
export const updateInforLogin = createAsyncThunk(
    'auth/register',
    async (datas) => {
        const { game, data } = datas
        const response = await post(`auth/register/${game}`, data)

        return response
    }
)

// confirm password non token 
export const confirmPasswordNonToken = createAsyncThunk(
    'auth/confirmPasswordNonToken',
    async (data) => {
        const response = await post(`auth/confirmAppPassword_NT`, data)

        return response
    }
)

// confirm password
export const confirmAppPassword = createAsyncThunk(
    'auth/confirmPassword',
    async (data) => {
        const response = await post('auth/confirmAppPassword', data)

        return response
    }
)

// change email
export const changeEmail = createAsyncThunk(
    'auth/changeEmail',
    async (data) => {
        const response = await post('user/changeEmail', data)

        return response
    }
)

// change telegram
export const changeTelegram = createAsyncThunk(
    'auth/changeTelegram',
    async (data) => {
        const response = await post('user/changeTelegram', data)

        return response
    }
)

// change code password
export const changeCodePassword = createAsyncThunk(
    'auth/changeApppassword',
    async (data) => {
        const response = await post('user/changeApppassword', data)

        return response
    }
)

// get account page admin
export const getAccountAdmin = createAsyncThunk(
    'auth/getAccountAdmin',
    async () => {
        const response = await get('admin/account')

        return response
    }
)

// update waring page admin
export const updateWaringAdmin = createAsyncThunk(
    'auth/updateWarningAdmin',
    async (data) => {
        const response = await post('admin/updateWarning', data)

        return response
    }
)

// check gamesite user
export const checkGamesiteUser = createAsyncThunk(
    'auth/checkGamesite',
    async (namesite) => {
        const response = await get(`verify_gamesite_user/${namesite}`)

        return response
    }
)

// update infor account
export const updateInforAdmin = createAsyncThunk(
    'auth/updateInforAdmin',
    async (data) => {
        const response = await post(`admin/updateInformation`, data)

        return response
    }
)

// update token
export const updateToken = createAsyncThunk(
    'auth/updateToken',
    async (data) => {
        const response = await post(`admin/changeToken`, data)

        return response
    }
)
