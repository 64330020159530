import React, { useEffect, useState } from 'react'
import {
    KR_USER_CANCEL_DEPOSIT,
    KR_USER_EXCHANGE_DEPOSIT,
    KR_USER_TITLE_DEPOSIT
} from '../../../translations/kor'
import Popup from '../../../components/user/Popup'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getExchangeRate, getTokens } from '../../../reduxs/actions/token.action'
import WalletItem from './WalletItem'
import Loading from '../../../components/Loading'
import { getDashboardUser } from '../../../reduxs/actions/dashboard.action'
import { URL_SERVER_IMAGE } from '../../../utils/api'

export default function DepositPage() {
    const dispatch = useDispatch()

    const { game } = useParams()

    const { loadGetAllToken, dataTokens } = useSelector(state => state.token)
    const { dataDashboard } = useSelector(state => state.dashboard)

    const [isShowExchange, setIsShowExchange] = useState(false)

    const handleShowExchange = () => {
        setIsShowExchange(!isShowExchange)
    }

    useEffect(() => {
        dispatch(getTokens(game))
    }, [dispatch, game])

    useEffect(() => {
        if (!dataDashboard) {
            dispatch(getDashboardUser(game))
        }
    }, [game])

    if (loadGetAllToken || !dataTokens || !dataDashboard) {
        return <Loading />
    }

    const { gamepoint } = dataDashboard

    return (
        <>
            <h2 className='text-text14-20 font-bold'>{KR_USER_TITLE_DEPOSIT}</h2>

            <section className='bg-bg-point bg-no-repeat bg-cover p-5 rounded-16 gap-2.5 flex flex-col justify-between'>
                <p className='text-text14-20'>{gamepoint?.gamepoint_name}</p>

                <p className='text-text20-24 font-bold'>100 {gamepoint?.gamepoint_name} = {gamepoint?.rate} USDT</p>
            </section>

            <section className='p-5 rounded-16 flex flex-col items-end gap-4 bg-white text-black'>
                {
                    dataTokens.map(item => <WalletItem
                        key={item.name}
                        name={item.name}
                        total={item.total}
                        logo={item.logoToken}
                        link={item.id}
                    />)
                }

                <button onClick={handleShowExchange} className='text-text14-20 text-blue'>{KR_USER_EXCHANGE_DEPOSIT}</button>
            </section>

            <PopupExchangeWallet
                handleShowExchange={handleShowExchange}
                isShowExchange={isShowExchange}
            />
        </>
    )
}

export const PopupExchangeWallet = ({ isShowExchange, handleShowExchange }) => {
    const dispatch = useDispatch()

    const { game } = useParams()

    const { loadExchangeRate, dataExchangeRate, gamepoint } = useSelector(state => state.token)

    const [valuePoint, setValuePoint] = useState(gamepoint?.gamepoint_rate)

    const handleChangePoint = (e) => {
        if (e.target.value <= 0) {
            return setValuePoint(0)
        }

        setValuePoint(e.target.value)
    }

    useEffect(() => {
        dispatch(getExchangeRate(game))
    }, [dispatch, game])

    return (
        <Popup show={isShowExchange} handleShow={handleShowExchange}>
            {
                (loadExchangeRate || !dataExchangeRate || !gamepoint)
                    ?
                    <Loading />
                    :
                    <div className='flex flex-col gap-4'>
                        <div className='flex justify-between items-center w-full'>
                            <div className='flex items-center gap-2'>
                                <img src={`${URL_SERVER_IMAGE}${gamepoint?.gamepoint_logo}`} alt='icon' className='w-[26px] aspect-square rounded-full object-contain' />

                                <span className='text-text14-20'>{gamepoint?.gamepoint_name}</span>
                            </div>

                            <input
                                className='bg-lightBlue w-[62px] py-1 px-2 text-center rounded-6 text-text14-20'
                                defaultValue={valuePoint}
                                onChange={handleChangePoint}
                                type='number'
                            />
                        </div>

                        {
                            dataExchangeRate.map(item =>
                                <WalletItem
                                    key={item.name}
                                    name={item.name}
                                    total={parseFloat((valuePoint * (item.value_token / gamepoint.gamepoint_rate)).toFixed(3))}
                                    logo={item.logoToken}
                                />)
                        }

                        <ButtonPrimary title={KR_USER_CANCEL_DEPOSIT} className='text-white text-text14-20 w-full' onClick={handleShowExchange} />
                    </div>
            }
        </Popup>
    )
}
