export const setLocalStorage = (key, value) => {
    return localStorage.setItem(key, value)
}

export const getLocalStorage = (key) => {
    const result = localStorage.getItem(key)

    return result
}

export const removeItemLocalStorage = (key) => {
    return localStorage.removeItem(key)
}

export const clearLocalStorage = (key) => {
    return localStorage.clear()
}