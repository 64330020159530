import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

// get all token
export const getTokens = createAsyncThunk(
    'token/getall',
    async (game) => {
        const response = await get(`/getToken?namesite=${game}`)

        return response
    }
)

// get exchange rate
export const getExchangeRate = createAsyncThunk(
    'token/exchange',
    async (game) => {
        const response = await get(`/exchangeRate?namesite=${game}`)

        return response
    }
)

// change token item
export const changeToken = createAsyncThunk(
    'token/change',
    async (data) => {
        const response = await post(`user/changeToken`, data)

        return response
    }
)

// change token item
export const changeExchangRate = createAsyncThunk(
    'token/changeExchange',
    async (data) => {
        const response = await post(`admin/change_exchangeRate`, data)

        return response
    }
)
