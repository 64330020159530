import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/admin/TitlePage'
import { CREATE, KR_ADMIN_AGENT_USER, KR_ADMIN_GAMESITE_USER, KR_ADMIN_USER_SIDEBAR, KR_ADMIN_USER_USER } from '../../translations/kor'
import ListUserAdmin from '../../sections/admin/Users/ListUserAdmin'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllUser } from '../../reduxs/actions/user.action'
import Loading from '../../components/Loading'
import ButtonPrimary from '../../components/ButtonPrimary'
import CreateNewUser from '../../components/CreateNewUser'

export default function UserAdminPage() {
    const dispatch = useDispatch()

    const [isShowCreateUser, setIsShowCreateUser] = useState(false)

    const { game } = useParams()

    const { loadingGetUser, dataUsers } = useSelector(state => state.user)

    // show create user
    const handleShowCreateUser = () => {
        setIsShowCreateUser(!isShowCreateUser)
    }

    useEffect(() => {
        dispatch(getAllUser(game || ''))
    }, [dispatch, game])

    if (loadingGetUser || !dataUsers) {
        return <Loading />
    }

    return (
        <>
            <div className='flex justify-between items-center'>
                <TitlePage title={KR_ADMIN_USER_SIDEBAR} />
                <ButtonPrimary title={`${CREATE} +`} onClick={handleShowCreateUser} type='button' />
            </div>

            <section className='grid grid-cols-3 gap-4'>
                <TotalUserItem title={KR_ADMIN_AGENT_USER} value={123} />
                <TotalUserItem title={KR_ADMIN_GAMESITE_USER} value={123} />
                <TotalUserItem title={KR_ADMIN_USER_USER} value={123} />
            </section>

            <ListUserAdmin data={dataUsers} />

            <CreateNewUser handleShow={handleShowCreateUser} show={isShowCreateUser} />
        </>
    )
}

const TotalUserItem = ({ title, value }) => {
    return (
        <div className='p-5 text-center rounded-20 bg-darkblue'>
            <p className='mb-4 text-text16-26'>{title}</p>
            <p className='text-text40-50 font-bold'>{value}</p>
        </div>
    )
}