import React from 'react'

export default function NotifyComponent({ notify }) {
    return (
        <div
            className={`fixed top-0 left-1/2 -translate-x-1/2 ${!!notify ? 'translate-y-[10px]' : '-translate-y-[100px]'} duration-300 shadow-shadow-input rounded-12 p-3 text-black bg-white text-text14-20 z-50`}
        >
            {notify || 'hello'}
        </div>
    )
}
