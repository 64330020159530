import React, { useState } from 'react'
import { KR_ADMIN_ACCOUNT_CODE_FORM, KR_ADMIN_ACCOUNT_EMAIL_FORM, KR_ADMIN_ACCOUNT_ID_FORM, KR_ADMIN_ACCOUNT_LEVEL_FORM, KR_ADMIN_ACCOUNT_PASSWORD_FORM, KR_ADMIN_ACCOUNT_PHONE_FORM, KR_ADMIN_ACCOUNT_SITE_FORM, KR_ADMIN_ACCOUNT_TELEGRAM_FORM, KR_ADMIN_INFOR_ACCOUNT } from '../../../translations/kor'
import InputUpdateForm from '../../../components/InputUpdateForm'
import { BsPencilSquare } from 'react-icons/bs'
import ChangeInforAdmin from './ChangeInforAdmin'

export default function InforAccount({ data }) {
    const { id, telegram_id, email, level, password, namesite, app_password, phone } = data

    const [isShowUpdateInfor, setIsShowUpdateInfor] = useState(false)

    const handleShowUpdateInfor = () => {
        setIsShowUpdateInfor(!isShowUpdateInfor)
    }

    return (
        <section className='flex flex-col gap-4'>
            <div className='flex items-center gap-2'>
                <h3 className='text-text16-20 font-bold'>{KR_ADMIN_INFOR_ACCOUNT}</h3>

                <button type='button' onClick={handleShowUpdateInfor}>
                    <BsPencilSquare />
                </button>
            </div>

            <div className='bg-darkblue p-5 rounded-20 grid grid-cols-2 gap-x-5 gap-y-4'>
                <InputUpdateForm label={KR_ADMIN_ACCOUNT_ID_FORM} defaluValue={id} />
                <InputUpdateForm label={KR_ADMIN_ACCOUNT_EMAIL_FORM} defaluValue={email} />
                <InputUpdateForm label={KR_ADMIN_ACCOUNT_PASSWORD_FORM} defaluValue={password} />
                <InputUpdateForm label={KR_ADMIN_ACCOUNT_TELEGRAM_FORM} defaluValue={telegram_id} />
                <InputUpdateForm label={KR_ADMIN_ACCOUNT_LEVEL_FORM} defaluValue={level} />
                <InputUpdateForm label={KR_ADMIN_ACCOUNT_SITE_FORM} defaluValue={namesite || '...'} />
                <InputUpdateForm label={KR_ADMIN_ACCOUNT_PHONE_FORM} defaluValue={phone} />
                <InputUpdateForm label={KR_ADMIN_ACCOUNT_CODE_FORM} defaluValue={app_password} />
            </div>

            <ChangeInforAdmin handleShow={handleShowUpdateInfor} show={isShowUpdateInfor} data={data} />
        </section>
    )
}
