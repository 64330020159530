import React, { useRef, useState } from 'react'
import ButtonPrimary from './ButtonPrimary'
import { KR_USER_FIX_DEPOSIT } from '../translations/kor'

export default function InputChangeForm({ value, name, onChange, error, placeholder }) {
    const inputRef = useRef(null)

    const [isDisabled, setIsDisabled] = useState(true)

    const handleDisabled = () => {
        inputRef.current.focus()
        setIsDisabled(!isDisabled)
    }

    return (
        <>
            <div className='flex justify-between gap-3 items-center bg-lightBlue rounded-12 p-3'>
                <textarea
                    className={`text-text14-20 flex-grow brek-all resize-none ${isDisabled && 'bg-transparent'} textarea-sizing`}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    value={value}
                    disabled={isDisabled}
                    ref={inputRef}
                />

                <ButtonPrimary title={KR_USER_FIX_DEPOSIT} onClick={handleDisabled} className='flex-shrink-0 text-white text-text10-12 !py-1 !px-3' />
            </div>

            {
                error &&
                <p className='text-text10-12 text-red'>{error}</p>
            }
        </>
    )
}
