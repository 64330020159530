import { createSlice } from "@reduxjs/toolkit";
import { getAllPost, getDetailPost } from "../actions/post.action";

const postSlice = createSlice({
    name: 'post',
    initialState: {
        loadingGetAllPost: false,
        dataAllPost: undefined,

        loadingGetDetail: false,
        detailPost: undefined
    },
    reducers: {},
    extraReducers: builder => {
        // get all post
        builder.addCase(getAllPost.pending, (state, action) => {
            state.loadingGetAllPost = true
        })
        builder.addCase(getAllPost.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataAllPost = res.data
            }

            state.loadingGetAllPost = false
        })
        builder.addCase(getAllPost.rejected, (state, action) => {
            console.log('error')
        })

        // get detail post
        builder.addCase(getDetailPost.pending, (state, action) => {
            state.loadingGetDetail = true
        })
        builder.addCase(getDetailPost.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.detailPost = res.data
            }

            state.loadingGetDetail = false
        })
    }
})

export const { } = postSlice.actions
export default postSlice.reducer
