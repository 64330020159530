import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { URL_SERVER_IMAGE } from '../utils/api'

export default function DropdownToken({ data, value, handleSelectToken, isSelected, gamepoint_rate }) {
    const [isDropdown, setIsDropdown] = useState(false)

    const handleShowDropdown = () => {
        setIsDropdown(!isDropdown)
    }

    return (
        <div className='w-full relative'>
            <div className='flex justify-between items-center p-3 bg-lightBlue rounded-12'>
                <div className='flex items-center gap-2' onClick={handleShowDropdown}>
                    <img src={`${URL_SERVER_IMAGE}${isSelected?.logoToken}`} alt='icon' className='w-[26px] aspect-square rounded-full object-contain cursor-pointer' />

                    <div>
                        <button className='flex items-center gap-1' type='button'>
                            <span className='text-text14-20'>{isSelected?.name}</span>
                            <FaChevronDown className='text-text10-12' />
                        </button>

                        <span className='text-text10-12 block w-fit'>{isSelected?.fullname || ''}</span>
                    </div>
                </div>

                <p className='text-text14-20'>{parseFloat((value * (isSelected?.value_token / gamepoint_rate)).toFixed(3))}</p>
            </div>

            {
                isDropdown
                &&
                <div className='shadow-shadow rounded-12 absolute inset-x-0 top-full bg-white mt-1'>
                    <ul>
                        {
                            data.map(item => <DropdownItem key={item.id} name={item.name} handleSelectToken={handleSelectToken} item={item} handleShowDropdown={handleShowDropdown} />)
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

const DropdownItem = ({ name, handleSelectToken, item, handleShowDropdown }) => {
    return (
        <li className=' text-center text-text14-20 p-1.5 hover:bg-lightBlue duration-300 first:rounded-t-12 last:rounded-b-12 cursor-pointer' onClick={() => {
            handleShowDropdown()
            handleSelectToken(item)
        }}>
            {name}
        </li>
    )
}
