import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/api";

export const getAllPost = createAsyncThunk(
    'post/getall',
    async () => {
        const response = await get('/getPost')

        return response
    }
)

export const getDetailPost = createAsyncThunk(
    'post/getdetail',
    async (id) => {
        const response = await get(`getDetailPost/${id}`)

        return response
    }
)