import React, { useEffect, useState } from 'react'
import { PopupAdmin } from './user/Popup'
import { ERROR_REQUIRE, KR_ADMIN_UPDATE_USER } from '../translations/kor'
import renderError from './RenderError'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { getInforUser, updateInforUser } from '../reduxs/actions/user.action'
import Loading from './Loading'
import ButtonPrimary from './ButtonPrimary'
import ButtonSecondary from './ButtonSecondary'
import InputForm from './InputForm'
import LabelForm from './LabelForm'
import * as Yup from 'yup'
import ListChangeRate from '../sections/admin/Users/ListChangeRate'

export default function UpdateUser({ handleShow, show, id }) {
    const dispatch = useDispatch()

    const [isError, setIsError] = useState()
    const [initialValues, setInitialValues] = useState({
        password: ''
    })

    const { loadingGetInforUser, dataInforUser } = useSelector(state => state.user)

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object({
            id: Yup.string().required(ERROR_REQUIRE),
            password: Yup.string(),
            phone_number: Yup.string().required(ERROR_REQUIRE),
            email: Yup.string().required(ERROR_REQUIRE),
            telegram_id: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const dataSubmit = {
                user_id: values.id,
                password: values.password,
                phone: values.phone_number,
                email: values.email,
                telegram_id: values.telegram_id
            }

            const result = await dispatch(updateInforUser(dataSubmit))

            const res = result.payload

            if (res.status === 1) {
                handleShow()
            } else {
                setIsError(res.code)
            }
        }
    })

    useEffect(() => {
        dispatch(getInforUser(id))
    }, [id])

    useEffect(() => {
        if (dataInforUser) {
            setInitialValues({
                ...initialValues,
                ...dataInforUser.infor_user
            })
        }
    }, [dataInforUser])

    return (
        <PopupAdmin handleShow={handleShow} show={show} className='flex flex-col items-center gap-5 max-h-[600px] hiddenScroll overflow-y-auto'>
            {
                (loadingGetInforUser || !dataInforUser)
                    ?
                    <Loading />
                    :
                    <>
                        <h3 className='text-text30-40 font-bold'>{KR_ADMIN_UPDATE_USER}</h3>

                        <form className='w-full flex flex-col gap-4 text-white' onSubmit={formik.handleSubmit}>
                            {
                                isError
                                &&
                                <span>{renderError(isError)}</span>
                            }

                            <div className='w-full'>
                                <LabelForm label='ID' />
                                <InputForm
                                    disabled
                                    className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                                    value={formik.values.account_id}
                                />
                            </div>

                            <div className='w-full'>
                                <LabelForm label='비밀번호' />
                                <InputForm
                                    name='password'
                                    type='password'
                                    max={4}
                                    className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={(formik.touched.password && formik.errors.password) && formik.errors.password}
                                />
                            </div>

                            <div className='w-full'>
                                <LabelForm label='레벨' />
                                <InputForm
                                    className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                                    value={formik.values.role_name}
                                    disabled
                                />
                            </div>

                            <div className='w-full'>
                                <LabelForm label='핸드폰' />
                                <InputForm
                                    className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                                    value={formik.values.phone_number}
                                    name='phone_number'
                                    onChange={formik.handleChange}
                                    error={(formik.touched.phone_number && formik.errors.phone_number) && formik.errors.phone_number}
                                />
                            </div>

                            <div className='w-full'>
                                <LabelForm label='이메일' />
                                <InputForm
                                    name='email'
                                    className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                                />
                            </div>

                            <div className='w-full'>
                                <LabelForm label='텔레그램' />
                                <InputForm
                                    name='telegram_id'
                                    onChange={formik.handleChange}
                                    className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                                    value={formik.values.telegram_id}
                                    error={(formik.touched.telegram_id && formik.errors.telegram_id) && formik.errors.telegram_id}
                                />
                            </div>

                            <div className='w-full'>
                                <LabelForm label='사이트' />
                                <InputForm
                                    value={formik.values.site_name}
                                    className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                                    disabled
                                />
                            </div>

                            {
                                !!dataInforUser.exchangeRate
                                &&
                                <ListChangeRate data={dataInforUser.exchangeRate} />
                            }

                            <div className='grid grid-cols-2 gap-2 w-full'>
                                <ButtonSecondary title='취소' onClick={handleShow} />
                                <ButtonPrimary title='확인' onClick={formik.handleSubmit} type='submit' />
                            </div>
                        </form>
                    </>
            }
        </PopupAdmin>
    )
}
