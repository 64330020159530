export default function renderError(error) {
    switch (error) {
        case 'w_acopw': // pw or ac not match
            return 'Wrong account_id or password!'
        case 'f_index': // required
            return 'Incomplete information!'
        case 'nf_u':    // can't find user
            return 'error'
        case 'nf_t':    // can't find token
            return 'error'
        case 'achex':   // account has exists
            return 'account exists!'
        case 'gnp':     // game not found
            return 'error'
        case 'uknow':   // unknow
            return 'Unknow'
        case 'verifyAPF': // verify app password failed
            return '코드가 잘못되었어요.'
        case 'exr_notf': // exchange rate not found
            return 'error'
        case 'not_author': // 403
            return 'You aren\'t admin!'
        case 'e_exists': // email already exists
            return 'Email Already Exixts!'
        case 'd_f':   // deposit request failed
            return 'error'
        case 'c_f':   // created failed
            return 'error'
    }
}
