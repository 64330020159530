import React, { useState } from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs'

export default function InputForm({ type, placeholder, value, onChange, name, error, className, max, defaultValue, forcus = false, disabled = false }) {
    const [showPassword, setShowPassword] = useState(false)

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div className='w-full '>
            <div className='relative'>
                <input
                    type={showPassword ? 'text' : type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    maxLength={max}
                    defaultValue={defaultValue}
                    autoFocus={forcus}
                    disabled={disabled}
                    className={`w-full rounded-8 text-text14-20 lg:text-text16-20 py-1.5 px-3 lg:px-4 lg:py-2.5 ${type === 'password' && '!pr-[36px]'} text-black bg-lightBlue ${className}`}
                />

                {
                    type === 'password'
                    &&
                    <button className='absolute top-1/2 -translate-y-1/2 right-3 text-deactivate text-sm' type='button' onClick={handleShowPassword}>{showPassword ? <BsEye /> : <BsEyeSlash />}</button>
                }
            </div>

            {
                error
                &&
                <span className='text-text10-12 text-red'>{error}</span>
            }
        </div>
    )
}
