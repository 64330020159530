import React, { useEffect } from 'react'
import defaultAvatar from '../../assets/images/avatarpng.png'
import { NavLink } from 'react-router-dom'
import { BsPerson } from 'react-icons/bs'
import { KR_USER_LOGOUT } from '../../translations/kor'
import { IoLogOutOutline } from 'react-icons/io5'
import { IoIosRepeat } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getInforAccount, logout } from '../../reduxs/actions/auth.action'

const ListLink = [
    {
        id: 1,
        link: 'my-wallet',
        title: ' 마이페이지',
        icon: <BsPerson />
    },
]

export default function SidebarUser({ isSidebar, handleShow }) {
    const dispatch = useDispatch()

    const { nameUser, email } = useSelector(state => state.auth)

    const handleLogout = () => {
        dispatch(logout())
    }

    useEffect(() => {
        if (nameUser && email) {
            dispatch(getInforAccount())
        }
    }, [nameUser, email])

    return (
        <>
            <div
                className={`absolute inset-0 h-screen right-0 ${isSidebar ? '!z-50 bg-bgOpacity' : 'z-0 invisible'} duration-300`}
                onClick={handleShow}>
            </div>

            <div className={`${isSidebar ? '' : 'translate-x-[300px]'} absolute top-0 right-0 duration-300 w-[267px] h-screen !z-50 bg-bg text-white shadow-shadow-sidebar overflow-hidden p-5 rounded-l-24 flex flex-col gap-6`}>
                <div className='flex gap-4 items-start'>
                    <img src={defaultAvatar} alt='avatar' className='w-9 h-9 rounded-full object-contain' />

                    <div className=''>
                        <h4 className='text-text14-20 font-bold'>{nameUser}</h4>
                        <span className='text-text10-12 block mb-0.5'>{email}</span>
                    </div>
                </div>

                <div className='flex flex-col justify-between flex-grow'>
                    <ul className='flex flex-col gap-2.5'>
                        {
                            ListLink.map(item =>
                                <NavItem
                                    key={item.id}
                                    title={item.title}
                                    to={item.link}
                                    icons={item.icon}
                                    active={item.id === 1}
                                    handleShow={handleShow}
                                />)
                        }
                        <li>
                            <button className='flex gap-1 items-center py-1.5 px-3 rounded-6 hover:bg-blue duration-300 w-full'>
                                <IoIosRepeat />

                                <span className='text-text14-20'>언어</span>
                            </button>
                        </li>
                    </ul>

                    <button className='flex gap-1 items-center py-1.5 px-3 rounded-6 hover:bg-blue duration-300' onClick={handleLogout}>
                        {<IoLogOutOutline />}

                        <span className='text-text14-20'>
                            {KR_USER_LOGOUT}
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

const NavItem = ({ to, title, icons, handleShow }) => {
    const style = 'flex gap-1 items-center py-1.5 px-3 rounded-8 hover:bg-blue duration-300'
    return (
        <li>
            <NavLink
                to={to}
                className={({ isActive, isPending }) => isActive ? `bg-blue ${style}` : `${style}`}
                onClick={handleShow}
            >
                {icons}

                <span className='text-text14-20'>
                    {title}
                </span>
            </NavLink>
        </li>
    )
}
