import React, { useEffect } from 'react'
import { PopupAdmin } from '../../../components/user/Popup'
import { ERROR_REQUIRE, KR_ADMIN_ACCOUNT_UPDATEINFOR_FORM, KR_ADMIN_ACCOUNT_URL_FORM } from '../../../translations/kor'
import ButtonSecondary from '../../../components/ButtonSecondary'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useFormik } from 'formik'
import LabelForm from '../../../components/LabelForm'
import InputForm from '../../../components/InputForm'
import { useDispatch, useSelector } from 'react-redux'
import { getLevels } from '../../../reduxs/actions/Level.action'
import { getAllGameSite } from '../../../reduxs/actions/gamesite.action'
import Loading from '../../../components/Loading'
import * as Yup from 'yup'
import { updateInforAdmin } from '../../../reduxs/actions/auth.action'

export default function ChangeInforAdmin({ handleShow, show, data }) {
    const dispatch = useDispatch()

    const { id, telegram_id, email, level, namesite, phone } = data

    const { dataLevel } = useSelector(state => state.level)
    const { dataGamesite } = useSelector(state => state.gamesite)

    const formik = useFormik({
        initialValues: {
            phone: phone,
            email: email,
            telegram: telegram_id,
            password: '',
            app_password: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            phone: Yup.string().required(ERROR_REQUIRE),
            email: Yup.string().required(ERROR_REQUIRE),
            telegram: Yup.string().required(ERROR_REQUIRE),
            password: Yup.string(),
            app_password: Yup.string(),
        }),
        onSubmit: async values => {
            const result = await dispatch(updateInforAdmin(values))

            const res = result.payload

            if (res.status === 1) {
                handleShow()
            }
        }
    })

    useEffect(() => {
        dispatch(getLevels())
        dispatch(getAllGameSite())
    }, [dispatch])

    if (!dataGamesite || !dataLevel) {
        return <Loading />
    }

    return (
        <PopupAdmin handleShow={handleShow} show={show} className='flex flex-col items-center gap-5'>
            <h3 className='lg:text-text30-40 text-text20-24 font-bold'>{KR_ADMIN_ACCOUNT_UPDATEINFOR_FORM}</h3>

            <form className='flex flex-col items-center gap-5 w-full'>
                <div className='w-full'>
                    <LabelForm label='ID' />
                    <InputForm
                        disabled
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        value={id}
                    />
                </div>

                <div className='w-full'>
                    <LabelForm label='비밀번호' />
                    <InputForm
                        name='password'
                        type='password'
                        max={4}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={(formik.touched.password && formik.errors.password) && formik.errors.password}
                    />
                </div>

                <div className='w-full'>
                    <LabelForm label='레벨' />
                    <InputForm
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        value={level}
                        disabled
                    />
                </div>

                <div className='w-full'>
                    <LabelForm label='핸드폰' />
                    <InputForm
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        value={formik.values.phone}
                        name='phone'
                        onChange={formik.handleChange}
                        error={(formik.touched.phone && formik.errors.phone) && formik.errors.phone}
                    />
                </div>

                <div className='w-full'>
                    <LabelForm label='이메일' />
                    <InputForm
                        name='email'
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                    />
                </div>

                <div className='w-full'>
                    <LabelForm label='텔레그램' />
                    <InputForm
                        name='telegram'
                        onChange={formik.handleChange}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        value={formik.values.telegram}
                        error={(formik.touched.telegram && formik.errors.telegram) && formik.errors.telegram}
                    />
                </div>

                <div className='w-full'>
                    <LabelForm label={KR_ADMIN_ACCOUNT_URL_FORM} />
                    <InputForm
                        value={namesite}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        disabled
                    />
                </div>

                <div className='w-full'>
                    <LabelForm label='보안 코드' />
                    <InputForm
                        name='app_password'
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        type='password'
                        max={4}
                        values={formik.values.app_password}
                        onChange={formik.handleChange}
                        error={(formik.touched.app_password && formik.errors.app_password) && formik.errors.app_password}
                    />
                </div>

                <div className='grid grid-cols-2 gap-2 w-full'>
                    <ButtonSecondary title='취소' onClick={handleShow} />
                    <ButtonPrimary title='확인' onClick={formik.handleSubmit} type='submit' />
                </div>
            </form>
        </PopupAdmin>
    )
}
