import React, { useEffect, useRef } from 'react'

export default function Popup({ children, handleShow, show, className }) {
    const contentRef = useRef()

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, handleShow, show]);

    return (
        <div className={`fixed inset-0 flex justify-center items-center ${show ? 'bg-bgOpacity z-40' : 'z-0 invisible'} duration-300`}>
            <div className={`max-w-[395px] w-full ${show ? 'opacity-100' : 'opacity-0'} duration-300 mx-5 bg-white p-4 rounded-16 text-black ${className}`} ref={contentRef}>
                {children}
            </div>
        </div>
    )
}

export function PopupAdmin({ children, handleShow, show, className }) {
    const contentRef = useRef()

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, handleShow, show]);

    return (
        <div className={`fixed inset-0 flex justify-center items-center ${show ? 'bg-bgOpacity z-40' : 'z-0 invisible'} duration-300`}>
            <div className={`max-w-[576px] w-full ${show ? 'opacity-100' : 'opacity-0'} duration-300 mx-5 bg-darkblue p-4 rounded-16 text-white ${className}`} ref={contentRef}>
                {children}
            </div>
        </div>
    )
}


