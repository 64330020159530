import React, { useEffect, useRef, useState } from 'react'
import TitlePage from '../../components/admin/TitlePage'
import { CREATE, DELETE, FIX, KR_ADMIN_BANNER_CONTENT_TABLE, KR_ADMIN_BANNER_IMAGE_TABLE, KR_ADMIN_BANNER_LINK_TABLE, KR_ADMIN_BANNER_NAME_TABLE, KR_ADMIN_BANNER_SIDEBAR } from '../../translations/kor'
import { IoEllipsisVertical } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { deleteBanners, getBanners } from '../../reduxs/actions/banner.action'
import Loading from '../../components/Loading'
import ButtonPrimary from '../../components/ButtonPrimary'
import CreateBanner from '../../components/CreateBanner'
import PopupDelete from '../../components/admin/PopupDelete'
import Updatebanner from '../../components/Updatebanner'

export default function BannersPage() {
    const dispatch = useDispatch()

    const [isShowCreate, setIsShowCreate] = useState(false)

    const { loadingGetBanner, dataBanner } = useSelector(state => state.banner)

    const handleShowCreate = () => {
        setIsShowCreate(!isShowCreate)
    }

    useEffect(() => {
        dispatch(getBanners())
    }, [])

    if (loadingGetBanner || !dataBanner) {
        return <Loading />
    }

    return (
        <>
            <div className='flex justify-between items-center'>
                <TitlePage title={KR_ADMIN_BANNER_SIDEBAR} />
                <ButtonPrimary title={`${CREATE} +`} type='button' onClick={handleShowCreate} />
            </div>

            <section className=''>
                <table className='w-full'>
                    <thead>
                        <tr className='text-text16-20 font-bold border-b border-stroke'>
                            <th className='pb-4 px-2.5 text-center w-1/5'>{KR_ADMIN_BANNER_NAME_TABLE}</th>
                            <th className='pb-4 px-2.5 text-center w-1/5'>{KR_ADMIN_BANNER_IMAGE_TABLE}</th>
                            <th className='pb-4 px-2.5 text-center w-1/5'>{KR_ADMIN_BANNER_LINK_TABLE}</th>
                            <th className='pb-4 px-2.5 text-center w-2/3'>{KR_ADMIN_BANNER_CONTENT_TABLE}</th>
                            <th className='pb-4 text-center w-5'></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataBanner.map((item, index) =>
                                <RowBannerItem
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    content={item.content}
                                    image={item.image}
                                    link={item.link}
                                    last={(index + 1) === dataBanner.length}
                                    data={item}
                                />)
                        }
                    </tbody>
                </table>

                <CreateBanner handleShow={handleShowCreate} show={isShowCreate} />
            </section>
        </>
    )
}

const RowBannerItem = ({ id, name, image, link, content, last, data }) => {
    const dispatch = useDispatch()

    const [isShowDelete, setIsShowDelete] = useState(false)
    const [isShowOption, setIsShowOption] = useState(false)
    const [isShowUpdate, setIsShowUpdate] = useState(false)

    // show option
    const handleShowOption = () => {
        setIsShowOption(!isShowOption)
    }

    // show update banner
    const handleShowUpdate = () => {
        setIsShowUpdate(!isShowUpdate)
    }

    // show popup delete
    const handleShowDelete = () => {
        setIsShowDelete(!isShowDelete)
    }

    // show popup delete
    const handleDeleteBanner = async () => {
        const dataSubmit = {
            banner_id: id
        }

        const result = await dispatch(deleteBanners(dataSubmit))

        const res = result.payload

        if (res.status === 1) {
            handleShowDelete()
        }
    }

    const contentRef = useRef()

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShowOption()
            }
        }

        if (isShowOption) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, handleShowOption, isShowOption]);

    return (
        <>
            <PopupDelete
                isShow={isShowDelete}
                handleCancel={handleShowDelete}
                content={<>
                    <p>배너를 삭제 하시겠습니까?</p>
                    <p>삭제된 배너 복구할 수 없습니다.</p>
                </>}
                handleConfirm={handleDeleteBanner}
            />

            {
                isShowUpdate
                &&
                <Updatebanner handleShow={handleShowUpdate} show={isShowUpdate} data={data} />
            }

            <tr className='border-b border-stroke'>
                <td className='py-4 text-center px-2.5'>{name}</td>
                <td className='py-4 text-center px-2.5'>
                    <p className='line-clamp-2 break-all'>
                        {image}
                    </p>
                </td>
                <td className='py-4 text-center px-2.5'>
                    <p className='line-clamp-2 break-all'>
                        {link}
                    </p>
                </td>
                <td className='py-4 text-center px-2.5'>
                    <p className='line-clamp-2'>{content}</p>
                </td>
                <td className='py-4 text-center w-5'>
                    <div className='relative'>
                        <button type='button' onClick={handleShowOption}>
                            <IoEllipsisVertical />
                        </button>

                        {
                            isShowOption
                            &&
                            <div className={`absolute ${last ? 'bottom-full' : 'top-full'} right-0 w-[188px] bg-white rounded-12 text-black`} ref={contentRef}>
                                <ul className='grid text-center'>
                                    <li>
                                        <button className='px-4 py-2.5 hover:bg-lightBlue w-full first:rounded-t-12 duration-200' onClick={handleShowUpdate}>
                                            {FIX}
                                        </button>
                                    </li>
                                    <li>
                                        <button className='px-4 py-2.5 hover:bg-lightBlue w-full last:rounded-b-12 duration-200' onClick={handleShowDelete}>
                                            {DELETE}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div></td>
            </tr>
        </>
    )
}
