import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

// get all transaction
export const getAllTransaction = createAsyncThunk(
    'transaction/getall',
    async (game) => {
        const response = await get(`admin/historyTransaction?namesite=${game}`)

        return response
    }
)

// get all transaction
export const getAllTransactionUser = createAsyncThunk(
    'transaction/getallUser',
    async (game) => {
        const response = await get(`user/historyTransactionUser`)

        return response
    }
)

// get transaction item 
export const getDetailTransaction = createAsyncThunk(
    'transaction/getdetail',
    async (id) => {
        const response = await get(`admin/getTransaction/${id}`)

        return response
    }
)

// update transaction item 
export const updateDetailTransaction = createAsyncThunk(
    'transaction/updateDetail',
    async (data) => {
        const response = await post(`admin/updateTransaction`, data)

        return response
    }
)

// update transaction item 
export const getTransactionStatus = createAsyncThunk(
    'transaction/getStatus',
    async () => {
        const response = await get(`admin/listTransactionStatus`)

        return response
    }
)
