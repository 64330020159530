import React from 'react'
// import { BsPencilSquare } from 'react-icons/bs'

export default function InputUpdateForm({ label, defaluValue, lock }) {
    return (
        <div>
            <label className='block mb-2.5 text-text14-20'>{label}</label>

            <div className={`flex justify-between items-center py-2 px-6 ${lock ? 'bg-input-lock text-deactivate' : 'bg-input'} rounded-12`}>
                <p className='text-text16-26'>{defaluValue}</p>

                {
                    // lock ? '' : <button><BsPencilSquare /></button>
                }
            </div>
        </div>
    )
}
