import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'

export default function RedeirectIfAuth({ children }) {
    const { isAuthentication, userRole } = useSelector(state => state.auth)

    const { gamesite, game } = useParams()

    const isNavigateTo = (role) => {
        switch (role) {
            case 'user':
                return `/user/${gamesite}`

            case 'game-admin':
                return `/admin/${game}`

            case 'agent-admin':
                return `/admin/${game}`

            case 'total-admin':
                return `/admin/${game}`
        }
    }

    return isAuthentication ? <Navigate to={isNavigateTo(userRole)} /> : children
}
