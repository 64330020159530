import React, { useEffect } from 'react'
import { KR_USER_TITLE_POSTS } from '../../../translations/kor'
import { MdOutlineAccessTime } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDetailPost } from '../../../reduxs/actions/post.action'
import Loading from '../../../components/Loading'
import moment from 'moment/moment'

export default function PostDetail() {
    const dispatch = useDispatch()

    const { id } = useParams()

    const { loadingGetDetail, detailPost } = useSelector(state => state.post)

    useEffect(() => {
        dispatch(getDetailPost(id))
    }, [id])

    if (loadingGetDetail || !detailPost) {
        return <Loading />
    }

    const { title, content, created_at } = detailPost

    return (
        <>
            <h2 className='text-text14-20 font-bold'>{KR_USER_TITLE_POSTS}</h2>

            <section className='p-5 bg-white rounded-16 flex-grow text-black'>
                <div className='flex flex-col gap-2 border-b border-black pb-4'>
                    <div className='text-text14-20 font-bold hover:text-blue duration-300'>{title}</div>

                    <p className='text-text10-12 flex gap-[5px] items-center'>
                        <MdOutlineAccessTime />
                        {moment(created_at).format('YYYY.MM.DD')}
                    </p>
                </div>

                <div className='pt-4 text-text14-20 whitespace-pre-line'>
                    {content}
                </div>
            </section>
        </>
    )
}
