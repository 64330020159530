import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { clearLocalStorage, getLocalStorage } from '../utils/localStorage'

export default function ProtectRouterUser({ children }) {
    const navigate = useNavigate()

    const token = getLocalStorage('token')
    const role = getLocalStorage('role')

    const { game } = useParams()

    const { isAuthentication, userRole } = useSelector(state => state.auth)

    useEffect(() => {
        if (role !== 'user') {
            clearLocalStorage()
            return navigate(`/auth/${game}`)
        }
    }, [role, navigate])

    return (isAuthentication || token) ? children : <Navigate to={`/auth/${game}`} />
}
