import { createSlice } from "@reduxjs/toolkit";
import { checkGameSite, getAllGameSite } from "../actions/gamesite.action";

const gamesiteSlice = createSlice({
    name: 'gamesite',
    initialState: {
        loadingCheckGamesite: false,
        statusCheck: undefined,
        nameGamesite: undefined,

        loadingGetAllGameSite: false,
        dataGamesite: undefined
    },
    extraReducers: builder => {
        // check game site
        builder.addCase(checkGameSite.pending, (state, action) => {
            state.loadingCheckGamesite = true
        })
        builder.addCase(checkGameSite.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.nameGamesite = res.data.name
                state.statusCheck = true
            } else {
                state.statusCheck = false
            }

            state.loadingCheckGamesite = false
        })

        // get game site
        builder.addCase(getAllGameSite.pending, (state, action) => {
            state.loadingGetAllGameSite = true
        })
        builder.addCase(getAllGameSite.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataGamesite = res.data
            }

            state.loadingGetAllGameSite = false
        })
    }
})

export const { } = gamesiteSlice.actions
export default gamesiteSlice.reducer
