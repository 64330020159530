import { createSlice } from "@reduxjs/toolkit";
import { getAllTransaction, getAllTransactionUser, getDetailTransaction, getTransactionStatus, updateDetailTransaction } from "../actions/transaction.action";

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        dataTransaction: undefined,
        loadingGetTransaction: false,

        loadinGetDetailTransaction: false,
        detailTransaction: undefined,

        loadinUpdateDetailTransaction: false,

        loadinGetTransactionStatus: false,
        dataTRansactionStatus: undefined,

        loadingGetTransactionUser: false
    },
    extraReducers: builder => {
        //get list transaction
        builder.addCase(getAllTransaction.pending, (state, action) => {
            state.loadingGetTransaction = true
        })
        builder.addCase(getAllTransaction.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataTransaction = res.data
            }

            state.loadingGetTransaction = false
        })

        //get list transaction
        builder.addCase(getAllTransactionUser.pending, (state, action) => {
            state.loadingGetTransactionUser = true
        })
        builder.addCase(getAllTransactionUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataTransaction = res.data
            }

            state.loadingGetTransactionUser = false
        })

        // get detail transaction
        builder.addCase(getDetailTransaction.pending, (state, action) => {
            state.loadinGetDetailTransaction = true
        })
        builder.addCase(getDetailTransaction.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.detailTransaction = res.data
            }

            state.loadinGetDetailTransaction = false
        })

        // update detail transaction
        builder.addCase(updateDetailTransaction.pending, (state, action) => {
            state.loadinUpdateDetailTransaction = true
        })
        builder.addCase(updateDetailTransaction.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                const updateDataTransaction = state.dataTransaction.map(item => {
                    if (item.id === res.data.id) {
                        return {
                            ...item,
                            status_id: res.data.status_id
                        }
                    }

                    return item
                })

                state.dataTransaction = updateDataTransaction
            }

            state.loadinUpdateDetailTransaction = false
        })

        // get status transaction
        builder.addCase(getTransactionStatus.pending, (state, action) => {
            state.loadinGetTransactionStatus = true
        })
        builder.addCase(getTransactionStatus.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataTRansactionStatus = res.data
            }

            state.loadinGetTransactionStatus = false
        })
    }
})

export const { } = transactionSlice.actions
export default transactionSlice.reducer