import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFoundPage() {
    return (
        <div className='text-white text-center flex flex-col justify-center items-center h-screen'>
            <h2 className='text-[100px] font-bold'>404</h2>
            <p>Not Found</p>

            <Link to={-1}>Back to Page</Link>
        </div>
    )
}
