import { createSlice } from "@reduxjs/toolkit";
import { changeExchangRate, changeToken, getExchangeRate, getTokens } from "../actions/token.action";

const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        loadGetAllToken: false,
        dataTokens: undefined,

        loadExchangeRate: false,
        dataExchangeRate: undefined,
        dataToken: undefined,
        gamepoint: 0,

        loadingChangeToken: false,

        loadingChangeExchangRate: false
    },
    extraReducers: builder => {
        // get all token
        builder.addCase(getTokens.pending, (state, action) => {
            state.loadGetAllToken = true
        })
        builder.addCase(getTokens.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataTokens = res.data
            }

            state.loadGetAllToken = false
        })

        // get exchange rate
        builder.addCase(getExchangeRate.pending, (state, action) => {
            state.loadExchangeRate = true
        })
        builder.addCase(getExchangeRate.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataExchangeRate = res.data.rate
                state.gamepoint = res.data.gamepoint
                state.dataToken = res.data
            }

            state.loadExchangeRate = false
        })

        // update token item
        builder.addCase(changeToken.pending, (state, action) => {
            state.loadingChangeToken = true
        })
        builder.addCase(changeToken.fulfilled, (state, action) => {
            const res = action.payload

            console.log(res)

            state.loadingChangeToken = false
        })

        // update token
        builder.addCase(changeExchangRate.pending, (state, action) => {
            state.loadingChangeExchangRate = true
        })
        builder.addCase(changeExchangRate.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataToken = res.data
            }

            state.loadingChangeExchangRate = false
        })
    }
})

export const { } = tokenSlice.actions
export default tokenSlice.reducer
