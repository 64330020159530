import { createSlice } from "@reduxjs/toolkit";
import { getDashboardAdmin, getDashboardUser, updateDetailTransactionDashboard } from "../actions/dashboard.action";

const dashboardSlice = createSlice({
    name: 'dashboardUser',
    initialState: {
        loadingDashboard: false,
        dataDashboard: undefined,

        loadingDashboardAdmin: false,
        dataDashboardAdmin: undefined,

        loadingUpdateTransaction: false
    },
    extraReducers: builder => {
        // get dashboard user
        builder.addCase(getDashboardUser.pending, (state, action) => {
            state.loadingDashboard = true
        })
        builder.addCase(getDashboardUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataDashboard = res.data
            }

            state.loadingDashboard = false
        })

        // get dashboard admin
        builder.addCase(getDashboardAdmin.pending, (state, action) => {
            state.loadingDashboardAdmin = true
        })
        builder.addCase(getDashboardAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataDashboardAdmin = res.data
            }

            state.loadingDashboardAdmin = false
        })

        // get dashboard admin
        builder.addCase(updateDetailTransactionDashboard.pending, (state, action) => {
            state.loadingUpdateTransaction = true
        })
        builder.addCase(updateDetailTransactionDashboard.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                const updateTransaction = state.dataDashboardAdmin.history_transaction.map(item => {
                    if (item.id === res.data.id) {
                        return {
                            ...item,
                            status_id: res.data.status_id
                        }
                    }

                    return item
                })

                state.dataDashboardAdmin.history_transaction = updateTransaction
            }

            state.loadingUpdateTransaction = false
        })
    }
})

export const { } = dashboardSlice.actions
export default dashboardSlice.reducer