import React from 'react'
import { FaAngleRight, FaRegBell } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { Autoplay } from 'swiper/modules';

export default function ListNotifyDashboard({ data }) {
    return (
        <Link to='posts' className='flex justify-between items-center p-3 bg-darkblue rounded-12'>
            <div className='flex gap-2 items-center'>
                <FaRegBell />

                <Swiper
                    direction='vertical'
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: true
                    }}
                    modules={[Autoplay]}
                    allowTouchMove={false}
                    className='mySwiper h-[25px]'
                >
                    {
                        data.map((item, index) =>
                            <SwiperSlide key={index}>
                                <span className='text-text14-20' >
                                    {item}
                                </span>
                            </SwiperSlide>)
                    }
                </Swiper>
            </div>

            <FaAngleRight />
        </Link>
    )
}

