import { createSlice } from "@reduxjs/toolkit";
import { getLevels } from "../actions/Level.action";

const levelSlice = createSlice({
    name: 'level',
    initialState: {
        loadingGetLevel: false,
        dataLevel: undefined
    },
    extraReducers: builder => {
        builder.addCase(getLevels.pending, (state, action) => {
            state.loadingGetLevel = true
        })
        builder.addCase(getLevels.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataLevel = res.data
            }

            state.loadingGetLevel = false
        })
    }
})

export const { } = levelSlice.actions
export default levelSlice.reducer
