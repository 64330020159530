import React, { useState } from 'react'
import { URL_SERVER_IMAGE } from '../utils/api'
import { FaChevronDown } from 'react-icons/fa'

export default function DropdownDeposit({ data, value, handleSelectToken, isSelected, handleChangePoint }) {
    const [isDropdown, setIsDropdown] = useState(false)

    const handleShowDropdown = () => {
        setIsDropdown(!isDropdown)
    }

    return (
        <div className='w-full relative'>
            <div className='flex justify-between items-center p-3 bg-lightBlue rounded-12'>
                <div className='flex items-center gap-2' onClick={handleShowDropdown}>
                    <img src={`${URL_SERVER_IMAGE}${isSelected?.logoToken}`} alt='icon' className='w-[26px] aspect-square rounded-full object-contain cursor-pointer' />

                    <div>
                        <button className='flex items-center gap-1' type='button'>
                            <span className='text-text14-20'>{isSelected?.name}</span>
                            <FaChevronDown className='text-text10-12' />
                        </button>

                        <span className='text-text10-12 block w-fit'>{isSelected?.fullname}</span>
                    </div>
                </div>

                <input
                    className='border-b border-black w-10 text-text14-20 bg-transparent text-center'
                    onChange={handleChangePoint}
                    defaultValue={value}
                    type='number'
                />
            </div>

            {
                isDropdown
                &&
                <div className='shadow-shadow rounded-12 absolute inset-x-0 top-full bg-white mt-1'>
                    <ul>
                        {
                            data.map(item =>
                                <DropdownItem
                                    key={item.id}
                                    name={item.name}
                                    handleSelectToken={handleSelectToken}
                                    item={item}
                                    handleShowDropdown={handleShowDropdown}
                                />)
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

const DropdownItem = ({ name, handleSelectToken, item, handleShowDropdown }) => {
    return (
        <li
            className=' text-center text-text14-20 p-1.5 hover:bg-lightBlue duration-300 first:rounded-t-12 last:rounded-b-12 cursor-pointer'
            onClick={() => {
                handleSelectToken(item)
                handleShowDropdown()
            }}>
            {name}
        </li>
    )
}
