import React from 'react'
import Popup from './Popup'
import InputForm from '../InputForm'
import ButtonPrimary from '../ButtonPrimary'
import { KR_USER_CHANGE_TOKEN_MYWALET, KR_USER_CONFIRM_DEPOSIT } from '../../translations/kor'

export default function PopupChangeToken({ handleShow, show, formik, name, error, value }) {
    return (
        <Popup handleShow={handleShow} show={show} className='flex flex-col gap-4'>
            <h3 className='text-text14-20 font-bold text-center'>{KR_USER_CHANGE_TOKEN_MYWALET}</h3>

            <form className='flex flex-col items-center gap-3'>
                <InputForm
                    name={name}
                    onChange={formik.handleChange}
                    error={error}
                    value={value}
                    className='textarea-sizing break-all'
                />

                <ButtonPrimary title={KR_USER_CONFIRM_DEPOSIT} className='text-white w-[140px]' onClick={formik.handleSubmit} />
            </form>
        </Popup>
    )
}
