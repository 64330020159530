import React, { useState } from 'react'
import { PopupAdmin } from './user/Popup'
import { ERROR_REQUIRE, KR_ADMIN_CREATE_BLOG } from '../translations/kor'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import LabelForm from './LabelForm'
import InputForm from './InputForm'
import renderError from './RenderError'
import TextareaForm from './TextareaForm'
import ButtonPrimary from './ButtonPrimary'
import ButtonSecondary from './ButtonSecondary'
import { createBlog } from '../reduxs/actions/blog.action'
import { useDispatch } from 'react-redux'

export default function CreateBlog({ handleShow, show }) {
    const dispatch = useDispatch()

    const [isError, setIsError] = useState()

    const formik = useFormik({
        initialValues: {
            title: '',
            content: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required(ERROR_REQUIRE),
            content: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(createBlog(values))

            const res = result.payload

            if (res.status === 1) {
                handleShow()
            } else {
                setIsError(res.code)
            }
        }
    })

    return (
        <PopupAdmin handleShow={handleShow} show={show} className='flex flex-col items-center gap-5'>
            <h3 className='text-text30-40 font-bold'>{KR_ADMIN_CREATE_BLOG}</h3>

            <form className='w-full flex flex-col gap-4 text-white' onSubmit={formik.handleSubmit}>
                {
                    isError
                    &&
                    <p className='text-text14-20 text-red w-full text-center'>{renderError(isError)}</p>
                }

                <div>
                    <LabelForm label='제목' htmlFor='title' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='title'
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        placeholder={'이름 입력해주세요'}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        error={(formik.touched.title && formik.errors.title) && formik.errors.title}
                    />
                </div>

                <div>
                    <LabelForm label='콘텐츠' htmlFor='content' className='!text-text14-20' />
                    <TextareaForm
                        className='!bg-input resize-none w-full'
                        row={6}
                        name='content'
                        value={formik.values.content}
                        onChange={formik.handleChange}
                        placeholder='콘텐츠 입력해주세요...'
                        error={(formik.touched.banner && formik.errors.banner) && formik.errors.banner}
                    />
                </div>

                <div className='grid grid-cols-2 gap-2'>
                    <ButtonSecondary title='취소' onClick={handleShow} />
                    <ButtonPrimary title='확인' onClick={formik.handleSubmit} type='submit' />
                </div>
            </form>
        </PopupAdmin>
    )
}
