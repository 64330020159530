import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

// dashboard user
export const getDashboardUser = createAsyncThunk(
    'dashboardUser/get',
    async (game) => {
        const response = await get(`/dashboard?namesite=${game}`)

        return response
    }
)

// dashboard admin
export const getDashboardAdmin = createAsyncThunk(
    'dashboardAdmin/get',
    async (game) => {
        const response = await get(`admin/dashboard?namesite=${game}`)

        return response
    }
)


// update transaction item 
export const updateDetailTransactionDashboard = createAsyncThunk(
    'dashboard/updateDetail',
    async (data) => {
        const response = await post(`admin/updateTransaction`, data)

        return response
    }
)