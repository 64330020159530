import React from 'react'
import Popup, { PopupAdmin } from '../user/Popup'
import ButtonSecondary from '../ButtonSecondary'
import ButtonPrimary from '../ButtonPrimary'

export default function PopupDelete({ content, handleCancel, handleConfirm, isShow, error }) {
    return (
        <Popup handleShow={handleCancel} show={isShow} className='text-center flex flex-col gap-5'>
            <div>{content}</div>

            {
                error &&
                <p className='text-text10-12 text-red'>{error}</p>
            }

            <div className='grid grid-cols-2 gap-4 text-white'>
                <ButtonSecondary title='취소' onClick={handleCancel} />
                <ButtonPrimary title='확인' onClick={handleConfirm} type='submit' />
            </div>
        </Popup>
    )
}
