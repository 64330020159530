import { createSlice } from "@reduxjs/toolkit";
import { createdeposit, getdeposit } from "../actions/deposit.action";

const depositSlice = createSlice({
    name: 'deposit',
    initialState: {
        loadGetDeposit: false,
        dataDeposits: undefined,

        loadCreateDeposit: false,
    },
    extraReducers: builder => {
        // get all deposit
        builder.addCase(getdeposit.pending, (state, action) => {
            state.loadGetDeposit = true
        })
        builder.addCase(getdeposit.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataDeposits = res.data
            }

            state.loadGetDeposit = false
        })

        // craete deposit
        builder.addCase(createdeposit.pending, (state, action) => {
            state.loadCreateDeposit = true
        })
        builder.addCase(createdeposit.fulfilled, (state, action) => {
            state.loadCreateDeposit = false
        })
    }
})

export const { } = depositSlice.actions
export default depositSlice.reducer
