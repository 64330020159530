import React, { useState } from 'react'
import ButtonSecondary from '../../components/ButtonSecondary'
import ButtonPrimary from '../../components/ButtonPrimary'
import {
    KR_USER_CONFIRM_DEPOSIT,
    KR_USER_INPUT_EMAIL_MYWALET,
    KR_USER_LABEL_EMAIL_MYWALET,
    KR_USER_OFF_DEPOSIT,
    KR_USER_TITLE_EMAIL_MYWALET
} from '../../translations/kor'
import Popup from '../../components/user/Popup'
import LabelForm from '../../components/LabelForm'
import InputForm from '../../components/InputForm'
import { PopupConfirmCodePin } from '../../components/user/PopupConfirmCodePin'
import { useFormik } from 'formik'
import { ERROR_REQUIRE } from '../../translations/kor'
import { changeEmail, confirmAppPassword } from '../../reduxs/actions/auth.action'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'

export default function ConfirmEmail({ show, handleShow }) {
    const dispatch = useDispatch()

    const [isConfirm, setIsConfirm] = useState(false)
    const [isError, setIsError] = useState(false)

    const formik = useFormik({
        initialValues: {
            app_password: ''
        },
        validationSchema: Yup.object({
            app_password: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(confirmAppPassword(values))

            if (result.payload.status === 1) {
                setIsConfirm(true)
            }
        }
    })

    return (
        <Popup show={show} handleShow={handleShow}>
            {
                isConfirm
                    ?
                    <PopupChangeEmail handleShow={handleShow} />
                    :
                    <PopupConfirmCodePin formik={formik} isError={isError} setIsError={setIsError} />
            }
        </Popup>
    )
}

const PopupChangeEmail = ({ handleShow }) => {
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(changeEmail(values))

            if (result.payload.status === 1) {
                handleShow()
            }
        }
    })

    return (
        <div className='flex flex-col gap-4'>
            <h4 className='text-text16-20 font-bold text-center'>{KR_USER_TITLE_EMAIL_MYWALET}</h4>

            <form className='flex flex-col gap-4 w-full' onSubmit={formik.handleSubmit}>
                <div>
                    <LabelForm label={KR_USER_LABEL_EMAIL_MYWALET} htmlFor='email' />
                    <InputForm
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placeholder={KR_USER_INPUT_EMAIL_MYWALET}
                        error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                    />
                </div>
            </form>

            <div className='grid grid-cols-2 gap-2 text-white'>
                <ButtonSecondary title={KR_USER_OFF_DEPOSIT} onClick={handleShow} />
                <ButtonPrimary type='submit' title={KR_USER_CONFIRM_DEPOSIT} onClick={formik.handleSubmit} />
            </div>
        </div>
    )
}