import { createSlice } from "@reduxjs/toolkit";
import { createUser, deleteUser, getAllUser, getInforUser, updateInforUser } from "../actions/user.action";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        loadingGetUser: false,
        dataUsers: undefined,

        loadingCreateUser: false,

        loadingDeleteUser: false,

        loadingGetInforUser: false,
        dataInforUser: undefined,

        loadingUpdateInforUser: false
    },
    extraReducers: builder => {
        // get list user
        builder.addCase(getAllUser.pending, (state, action) => {
            state.loadingGetUser = true
        })
        builder.addCase(getAllUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataUsers = res.data
            }

            state.loadingGetUser = false
        })

        // create user
        builder.addCase(createUser.pending, (state, action) => {
            state.loadingCreateUser = true
        })
        builder.addCase(createUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                console.log(res)
            }

            state.loadingCreateUser = false
        })

        // delete user
        builder.addCase(deleteUser.pending, (state, action) => {
            state.loadingDeleteUser = true
        })
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                const updateDataUser = state.dataUsers.filter(item => item.id !== res.data.user_id)

                state.dataUsers = updateDataUser
            }

            state.loadingDeleteUser = false
        })

        // get infor user
        builder.addCase(getInforUser.pending, (state, action) => {
            state.loadingGetInforUser = true
        })
        builder.addCase(getInforUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                state.dataInforUser = res.data
            }

            state.loadingGetInforUser = false
        })

        // get infor user
        builder.addCase(updateInforUser.pending, (state, action) => {
            state.loadingUpdateInforUser = true
        })
        builder.addCase(updateInforUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status === 1) {
                const updateDataUser = state.dataUsers.map(item => {
                    if (item.id === res.data.id) {
                        return res.data
                    }

                    return item
                })

                state.dataUsers = updateDataUser
            }

            state.loadingUpdateInforUser = false
        })
    }
})

export const { } = userSlice.actions
export default userSlice.reducer
