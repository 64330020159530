import React, { useState } from 'react'
import ButtonPrimary from '../ButtonPrimary'
import { KR_USER_COPY_DEPOSIT, KR_USER_FIX_DEPOSIT } from '../../translations/kor'
import { URL_SERVER_IMAGE } from '../../utils/api'
import { copytext } from '../../utils/handle'
import UpdateToken from '../../sections/admin/MyAccount/UpdateToken'

export default function CompanyPointItem({ token_id, copy, logo, name, count, token, QAcode }) {
    const [isShowUpdateToken, setIsShowUpdateToken] = useState(false)

    const handleShowUpdateToken = () => {
        setIsShowUpdateToken(!isShowUpdateToken)
    }

    return (
        <>
            <div className='bg-bg-companyPoint p-5 rounded-20 bg-no-repeat bg-cover bg-center flex flex-col gap-4 w-full'>
                <div className='flex items-center gap-2 text-black'>
                    <img src={`${URL_SERVER_IMAGE}${logo}`} alt={`${name}`} className='w-[26px] aspect-square object-cover rounded-full' />
                    <span className='text-text16-26'>{name}</span>
                </div>

                <div className='flex flex-col gap-2.5 flex-grow'>
                    <p className={`${copy ? 'text-text14-20 flex-grow' : 'text-text40-50 font-bold'} text-black break-all`}>
                        {
                            copy
                                ?
                                token
                                :
                                `${count} ea`
                        }
                    </p>

                    {
                        copy &&
                        <div className='flex gap-2'>
                            <ButtonPrimary title={KR_USER_FIX_DEPOSIT} onClick={handleShowUpdateToken} className='w-fit text-text10-12 lg:py-1 !py-1 !px-3' />
                            <ButtonPrimary title={KR_USER_COPY_DEPOSIT} onClick={() => copytext(token)} className='w-fit text-text10-12 lg:py-1 !py-1 !px-3' />
                        </div>
                    }
                </div>
            </div>

            <UpdateToken
                handleShow={handleShowUpdateToken}
                show={isShowUpdateToken}
                token={token}
                QAcode={QAcode}
                token_id={token_id}
            />
        </>
    )
}

