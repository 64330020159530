import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/admin/TitlePage'
import { KR_ADMIN_HISTORY_SIDEBAR, KR_ADMIN_TRANSACTION_AMOUNT_TABLE, KR_ADMIN_TRANSACTION_DATE_TABLE, KR_ADMIN_TRANSACTION_NAME_TABLE, KR_ADMIN_TRANSACTION_STATUS_TABLE } from '../../translations/kor'
import { RowTransactionItem } from '../../sections/admin/Dashboard/LastTransaction'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllTransaction } from '../../reduxs/actions/transaction.action'
import Loading from '../../components/Loading'
import Dropdown from '../../components/Dropdown'

const dataFilter = [
    {
        id: 0,
        name: 'All'
    },
    {
        id: 1,
        name: 'In'
    },
    {
        id: 2,
        name: 'Out'
    },
]

export default function HistoryPage() {
    const dispatch = useDispatch()

    const { game } = useParams()

    const [isSelectFilter, setIsSelectFilter] = useState(0)

    const { dataTransaction, loadingGetTransaction } = useSelector(state => state.transaction)

    const handleSelectFilter = (filter) => {
        setIsSelectFilter(filter.id)
    }

    useEffect(() => {
        dispatch(getAllTransaction(game || ''))
    }, [game])

    if (loadingGetTransaction || !dataTransaction) {
        return <Loading />
    }

    return (
        <>
            <div className='flex justify-between items-center'>
                <TitlePage title={KR_ADMIN_HISTORY_SIDEBAR} />
                <Dropdown data={dataFilter} title='Filter' isSelect={isSelectFilter} handleClick={handleSelectFilter} />
            </div>

            <section className='p-5 pb-[5px] bg-darkblue rounded-20'>
                <table className='w-full'>
                    <thead>
                        <tr className='text-text16-20 font-bold '>
                            <th className='pb-4 text-start'>{KR_ADMIN_TRANSACTION_NAME_TABLE}</th>
                            <th className='pb-4 text-center'>{KR_ADMIN_TRANSACTION_DATE_TABLE}</th>
                            <th className='pb-4 text-center'>{KR_ADMIN_TRANSACTION_STATUS_TABLE}</th>
                            <th className='pb-4 text-end'>{KR_ADMIN_TRANSACTION_AMOUNT_TABLE}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataTransaction.map(item => {
                                if (!isSelectFilter || (item.type_transaction_id === isSelectFilter)) {
                                    return <RowTransactionItem
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        amount={item.amount}
                                        created_at={item.created_at}
                                        type_transaction_id={item.type_transaction_id}
                                        status_id={item.status_id}
                                        currency_name={item.currency_name}
                                        typeTransaction={item.type_transaction_id === 1 ? true : false}
                                    />
                                }
                            }
                            )
                        }
                    </tbody>
                </table>
            </section>
        </>
    )
}
