import React, { useEffect, useRef, useState } from 'react'
import { IoChevronDownOutline } from 'react-icons/io5'

export default function Dropdown({ data, title, handleClick, isSelect }) {
    const contentRef = useRef()

    const [isShowDropdow, setIsShowDropdown] = useState(false)

    const handleShowDropdown = () => {
        setIsShowDropdown(!isShowDropdow)
    }


    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShowDropdown()
            }
        }

        if (isShowDropdow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, handleShowDropdown, isShowDropdow]);

    return (
        <div className='relative'>
            <button className='py-2.5 px-4 bg-blue rounded-8 flex items-center gap-2' onClick={handleShowDropdown}>
                <span className='text-text16-26'>
                    {
                        isSelect
                            ?
                            data.filter(item => item.id === isSelect)[0].name
                            :
                            title
                    }
                </span>

                <IoChevronDownOutline />
            </button>

            {
                isShowDropdow
                &&
                <div className='absolute top-full mt-2 right-0 bg-white rounded-12 w-[200px]' ref={contentRef}>
                    <ul>
                        {
                            data.map(item =>
                                <li
                                    key={item.id}
                                    className={`hover:bg-blue ${(isSelect === item.id) && 'bg-blue'} duration-300 first:rounded-t-12 last:rounded-b-12`}
                                >
                                    <button
                                        className={`text-black w-full py-2.5 px-4 hover:text-white ${(isSelect === item.id) && 'text-white'} duration-300`}
                                        onClick={() => {
                                            handleShowDropdown()
                                            handleClick(item)
                                        }}
                                    >
                                        {item.name}
                                    </button>
                                </li>
                            )
                        }
                    </ul>
                </div>
            }
        </div>
    )
}
