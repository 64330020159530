import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDetailTransaction, updateDetailTransaction } from '../../reduxs/actions/transaction.action'
import Loading from '../../components/Loading'
import { URL_SERVER_IMAGE } from '../../utils/api'
import { useFormik } from 'formik'
import { BsChevronExpand } from 'react-icons/bs'
import {
    ERROR_REQUIRE,
    KR_USER_COPY_DEPOSIT,
    KR_USER_REQUEST_WITHDRAW,
    KR_USER_TITLE_MYWALLET_DEPOSIT
} from '../../translations/kor'
import ButtonPrimary from '../../components/ButtonPrimary'
import Popup from '../../components/user/Popup'
import { PopupConfirmCodePin } from '../../components/user/PopupConfirmCodePin'
import * as Yup from 'yup'
import { confirmAppPassword } from '../../reduxs/actions/auth.action'
import { updateDetailTransactionDashboard } from '../../reduxs/actions/dashboard.action'
import { useLocation, useParams } from 'react-router-dom'

export default function DetailDepositItem({ id }) {
    const dispatch = useDispatch()

    const { pathname } = useLocation()
    const { game } = useParams()

    const { loadinGetDetailTransaction, detailTransaction } = useSelector(state => state.transaction)
    const [isShowCodePin, setIsShowCodePin] = useState(false)
    const [statusSelect, setStatusSelect] = useState()

    const [confirmCodePin, setConfirmCodePin] = useState(false)
    const [isError, setIsError] = useState()

    const handleShowCodePin = () => {
        setIsShowCodePin(!isShowCodePin)
    }

    const handleSelectStatus = (status) => {
        setStatusSelect(status)
        formik.setFieldValue('status_transaction_id', status)
    }

    // formik
    const formik = useFormik({
        initialValues: {
            transaction_id: id,
            status_transaction_id: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            status_transaction_id: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: values => {
            if (!confirmCodePin) {
                handleShowCodePin()
            }
        }
    })

    // formik confirm code pin
    const formikConfirmPassword = useFormik({
        initialValues: {
            app_password: ''
        },
        validationSchema: Yup.object({
            app_password: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(confirmAppPassword(values))

            if (result.payload.status === 1) {
                const checkLocation = `${pathname}` === (game ? `/admin/${game}` : '/admin')

                const result = await dispatch(checkLocation ? updateDetailTransactionDashboard(formik.values) : updateDetailTransaction(formik.values))

                if (result.payload.status === 1) {
                    handleShowCodePin()
                }
            } else {
                setIsError(result.payload.code)
            }
        }
    })

    useEffect(() => {
        dispatch(getDetailTransaction(id))
    }, [id, dispatch])

    if (isShowCodePin) {
        return (
            <Popup show={isShowCodePin} handleShow={handleShowCodePin}>
                <PopupConfirmCodePin formik={formikConfirmPassword} isError={isError} setIsError={setIsError} />
            </Popup>
        )
    }

    if (loadinGetDetailTransaction || !detailTransaction) {
        return <Loading />
    }

    const { currency, gamepoint, list_status, token_address_user } = detailTransaction

    return (
        <form className='flex flex-col gap-5 items-center'>
            <h3 className='text-text30-40 font-bold'>세부내역</h3>

            <div className='w-full flex flex-col gap-4'>
                <div className='w-full flex flex-col items-center gap-2'>
                    <div className='flex justify-between items-center w-full p-3 bg-lightBlue rounded-12 text-black'>
                        <div className='flex items-center gap-2'>
                            <img src={`${URL_SERVER_IMAGE}${gamepoint.logoGame}`} alt='icon' className='w-[26px] aspect-square rounded-full object-contain' />

                            <span className='text-text14-20'>{gamepoint.name}</span>
                        </div>

                        <p className='border-b border-black min-w-10 text-text14-20 bg-transparent text-end'>{gamepoint.gamepoint_rate}</p>
                    </div>

                    <BsChevronExpand />

                    <div className='flex justify-between items-center w-full p-3 bg-lightBlue rounded-12 text-black'>
                        <div className='flex items-center gap-2'>
                            <img src={`${URL_SERVER_IMAGE}${currency.logoToken}`} alt='icon' className='w-[26px] aspect-square rounded-full object-contain' />

                            <div>
                                <button className='flex items-center gap-1 text-text14-20' type='button'>
                                    {currency?.name}
                                </button>

                                <span className='text-text10-12 block w-fit'>{currency.fullname}</span>
                            </div>
                        </div>

                        <p className='text-text14-20'>{currency.value_token}</p>
                    </div>
                </div>

                <div className='w-full flex flex-col gap-2'>
                    <h3 className='text-text14-20 font-bold'>{KR_USER_TITLE_MYWALLET_DEPOSIT}</h3>

                    <div className='flex gap-3 items-center justify-between bg-lightBlue rounded-12 p-3'>
                        <p className='break-all text-text14-20 text-black'>{token_address_user}</p>

                        <ButtonPrimary title={KR_USER_COPY_DEPOSIT} className='flex-shrink-0 text-white !text-text10-12' />
                    </div>
                </div>

                <div className='w-full flex flex-col gap-2'>
                    <h3 className='text-text14-20 font-bold'>{KR_USER_REQUEST_WITHDRAW}</h3>

                    <textarea className='resize-none bg-lightBlue w-full py-2.5 px-4 rounded-12 text-text14-20' placeholder='요청 입력하세요....' rows={5} />
                </div>

                <div className='grid grid-cols-4'>
                    {
                        list_status.map(item => {
                            if (item.id !== 1)
                                return <StatusDeposit
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    handleSelectStatus={handleSelectStatus}
                                    isSelected={item.id === statusSelect}
                                />
                        })
                    }

                </div>
                {
                    <p className='text-text14-20 text-red w-full text-center'>{formik.errors.status_transaction_id}</p>
                }

                <div className='text-center'>
                    <ButtonPrimary title='확인' className='w-[200px]' onClick={formik.handleSubmit} type='submit' />
                </div>
            </div>
        </form>
    )
}

const StatusDeposit = ({ id, name, handleSelectStatus, isSelected }) => {
    return (
        <button type='button' className={`${isSelected && 'text-blue'}`} onClick={() => handleSelectStatus(id)}>
            {name}
        </button>
    )
}
