import React, { useState } from 'react'
import Popup from '../../components/user/Popup'
import { PopupConfirmCodePin } from '../../components/user/PopupConfirmCodePin'
import { useFormik } from 'formik'
import {
    ERROR_REQUIRE,
    KR_USER_CONFIRM_DEPOSIT,
    KR_USER_INPUT_NEWCODE_MYWALET,
    KR_USER_OFF_DEPOSIT,
    KR_USER_TITLE_CODE_MYWALET
} from '../../translations/kor'
import { changeCodePassword, confirmAppPassword } from '../../reduxs/actions/auth.action'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import InputForm from '../../components/InputForm'
import ButtonSecondary from '../../components/ButtonSecondary'
import ButtonPrimary from '../../components/ButtonPrimary'

export default function ConfirmCodePin({ show, handleShow }) {
    const dispatch = useDispatch()

    const [isConfirm, setIsConfirm] = useState(false)
    const [isError, setIsError] = useState(false)

    const formik = useFormik({
        initialValues: {
            app_password: ''
        },
        validationSchema: Yup.object({
            app_password: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(confirmAppPassword(values))

            if (result.payload.status === 1) {
                setIsConfirm(true)
            }
        }
    })

    return (
        <Popup show={show} handleShow={handleShow}>
            {
                isConfirm
                    ?
                    <PopupChangeCodePin handleConfirm={handleShow} />
                    :
                    <PopupConfirmCodePin formik={formik} isError={isError} setIsError={setIsError} />
            }
        </Popup>
    )
}

const PopupChangeCodePin = ({ handleConfirm }) => {
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            app_password: ''
        },
        validateOnChange: Yup.object({
            app_password: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(changeCodePassword(values))

            if (result.payload.status === 1) {
                handleConfirm()
            }
        }
    })


    return (
        <div className='flex flex-col gap-4'>
            <h4 className='text-text16-20 font-bold text-center'>{KR_USER_TITLE_CODE_MYWALET}</h4>

            <form className='flex flex-col gap-4 w-full'>
                <div>
                    <InputForm
                        name='app_password'
                        type='password'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placeholder={KR_USER_INPUT_NEWCODE_MYWALET}
                        error={(formik.touched.app_password && formik.errors.app_password) && formik.errors.app_password}
                    />
                </div>
            </form>

            <div className='grid grid-cols-2 gap-2 text-white'>
                <ButtonSecondary title={KR_USER_OFF_DEPOSIT} onClick={handleConfirm} />
                <ButtonPrimary type='submit' title={KR_USER_CONFIRM_DEPOSIT} onClick={formik.handleSubmit} />
            </div>
        </div>
    )
}
