import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

// get withdraw
export const getWithDraw = createAsyncThunk(
    'withdraw/getall',
    async (game) => {
        const response = await get(`/getWithdraw?namesite=${game}`)

        return response
    }
)

// get withdraw
export const createWithDraw = createAsyncThunk(
    'withdraw/create',
    async (datas) => {
        const { game, data } = datas

        const response = await post(`/createWithdraw?namesite=${game}`, data)

        return response
    }
)