import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

// get list user
export const getAllUser = createAsyncThunk(
    'user/getall',
    async (game) => {
        const response = await get(`admin/listuser?namesite=${game}`)

        return response
    }
)

// create user
export const createUser = createAsyncThunk(
    'user/create',
    async (data) => {
        const response = await post(`admin/createdUser`, data)

        return response
    }
)

// delete user
export const deleteUser = createAsyncThunk(
    'user/delete',
    async (data) => {
        const response = await post(`admin/deleteUser`, data)

        return response
    }
)

// get infor user
export const getInforUser = createAsyncThunk(
    'user/getUser',
    async (idUser) => {
        const response = await get(`admin/getUser/${idUser}`)

        return response
    }
)

// update infor user
export const updateInforUser = createAsyncThunk(
    'user/updateUser',
    async (data) => {
        const response = await post(`admin/updateUser`, data)

        return response
    }
)

