import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { checkAuth } from "../../../reduxs/reducers/auth.reducer";
import LoginUser from "./LoginUser";
import RedeirectIfAuth from "../../../components/RedeirectIfAuth";
import { checkGameSite } from "../../../reduxs/actions/gamesite.action";
import Loading from "../../../components/Loading";

function Auth() {
  const dispatch = useDispatch()

  const { pathname } = useLocation()
  const { gamesite } = useParams()

  const { loadingCheckGamesite, statusCheck, nameGamesite } = useSelector(state => state.gamesite)

  useEffect(() => {
    dispatch(checkAuth())
  }, [dispatch])

  useEffect(() => {
    dispatch(checkGameSite(gamesite))
  }, [gamesite])

  if (loadingCheckGamesite || !statusCheck) {
    return <Loading className='text-white' />
  }

  return (
    <main className='flex justify-center items-center h-screen py-5 bg-bg-login bg-no-repeat bg-cover max-w-[476px] w-full'>
      {
        pathname === `/auth/${gamesite}`
          ?
          <RedeirectIfAuth>
            <LoginUser nameGamesite={nameGamesite} />
          </RedeirectIfAuth>
          :
          <Outlet />
      }
    </main>
  );
}

export default Auth;
