import React, { useState } from 'react'
import ButtonPrimary from '../ButtonPrimary'
import { ERROR_REQUIRE, KR_USER_COPY_DEPOSIT, KR_USER_FIX_DEPOSIT } from '../../translations/kor'
import { URL_SERVER_IMAGE } from '../../utils/api'
import { copytext } from '../../utils/handle'
import PopupChangeToken from './PopupChangeToken'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { changeToken } from '../../reduxs/actions/token.action'
import * as Yup from 'yup'
import renderError from '../RenderError'

export default function CompanyItem({ id, logo, address, name }) {
    const dispatch = useDispatch()

    const [isShow, setIsShow] = useState(false)
    const [isError, setIsError] = useState()
    const [addressToken, setAddressToken] = useState(address)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    const formik = useFormik({
        initialValues: {
            address: address,
            token_id: id
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            address: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(changeToken(values))

            const res = result.payload

            if (res.status === 1) {
                handleShow()
                setAddressToken(values.address)
            } else {
                setIsError(res.code)
            }
        }
    })

    return (
        <div className='flex flex-col gap-1 bg-lightBlue rounded-16 p-3'>
            <div className='flex gap-2 items-center'>
                <img src={`${URL_SERVER_IMAGE}${logo}`} alt={`${name}`} className='w-5 aspect-square rounded-full' />
                <span className='text-text14-20'>{name}</span>
            </div>

            <p className='break-all text-text14-20'>{addressToken}</p>

            <div className='flex text-white gap-1 text-text10-12 justify-end'>
                <ButtonPrimary title={KR_USER_FIX_DEPOSIT} onClick={handleShow} className='!text-text10-12' />
                <ButtonPrimary title={KR_USER_COPY_DEPOSIT} className='!text-text10-12' onClick={() => copytext(address)} />
            </div>

            <PopupChangeToken
                handleShow={handleShow}
                show={isShow}
                formik={formik}
                name='address'
                value={formik.values.address}
                error={(formik.touched.address && (formik.errors.address || isError)) && (renderError(isError) || formik.errors.address)}
            />
        </div>
    )
}
