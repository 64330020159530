import React, { useEffect, useState } from 'react'
import {
    KR_USER_ALL_TRANSACTION,
    KR_USER_AMOUNT_TABLE_TRANSACTION,
    KR_USER_IN_TRANSACTION,
    KR_USER_NAME_TABLE_TRANSACTION,
    KR_USER_OUT_TRANSACTION,
    KR_USER_TITLE_TRANSACTION
} from '../../../translations/kor'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTransactionUser } from '../../../reduxs/actions/transaction.action'
import Loading from '../../../components/Loading'
import { IoIosTrendingUp } from 'react-icons/io'

const dataFilter = [
    {
        id: 0,
        name: KR_USER_ALL_TRANSACTION
    },
    {
        id: 1,
        name: KR_USER_IN_TRANSACTION
    },
    {
        id: 2,
        name: KR_USER_OUT_TRANSACTION
    },
]

export default function TransactionPage() {
    const dispatch = useDispatch()

    const { dataTransaction, loadingGetTransactionUser } = useSelector(state => state.transaction)
    const [isSelectFilter, setIsSelectFilter] = useState(0)

    const handleSelectFilter = (filter) => {
        setIsSelectFilter(filter)
    }

    useEffect(() => {
        dispatch(getAllTransactionUser())
    }, [dispatch])

    if (loadingGetTransactionUser || !dataTransaction) {
        return <Loading />
    }

    return (
        <>
            <h2 className='text-text14-20 font-bold'>{KR_USER_TITLE_TRANSACTION}</h2>

            <div className='flex justify-center'>
                {
                    dataFilter.map(item =>
                        <button
                            key={item.id}
                            className={`px-2 font-bold ${item.id === isSelectFilter && 'text-blue'}`}
                            onClick={() => handleSelectFilter(item.id)}
                        >
                            {item.name}
                        </button>
                    )
                }
            </div>

            <div className='bg-white text-black rounded-16 p-4'>
                <table className='w-full'>
                    <thead>
                        <tr>
                            <th className='pb-4 w-2/3'>{KR_USER_NAME_TABLE_TRANSACTION}</th>
                            <th className='pb-4 w-1/3'>{KR_USER_AMOUNT_TABLE_TRANSACTION}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataTransaction.map(item => {
                                if (!isSelectFilter || (item.type_transaction_id === isSelectFilter)) {
                                    return <RowTransactionItem
                                        key={item.id}
                                        name={item.address}
                                        amount={item.amount}
                                        typeTransaction={item.type_transaction_id === 1 ? true : false}
                                        status_id={item.status_id}
                                        currency_name={item.currency_name}
                                    />
                                }
                            }
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

const RowTransactionItem = ({ name, amount, typeTransaction, status_id, currency_name }) => {
    return (
        <tr className='border-t border-deactivate text-text14-20'>
            <td className='w-2/3 py-4'>
                <div className='flex items-center gap-2'>
                    <IoIosTrendingUp className={`flex-shrink-0 ${typeTransaction && 'rotate-180'}`} />
                    <p className='break-all'>{name}</p>
                </div>
            </td>
            <td className={`w-1/3 px-1 py-4 text-center ${!typeTransaction && 'text-red'}`}>{amount} {currency_name} {renderStatus(status_id)}</td>
        </tr>
    )
}

const renderStatus = (status) => {
    switch (status) {
        case 1:
            return <span className='block text-black'>요청</span>
        case 2:
            return <span className='block text-[#27B73E]'>처리 중</span>
        case 3:
            return <span className='block text-[#3787FF]'>완료</span>
        case 4:
            return <span className='block text-red'>거부</span>
        case 5:
            return <span className='block text-[#595959]'>보류 중</span>

        default:
            break;
    }
}
